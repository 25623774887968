import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  user_token: any;

  // const token = localStorage.getItem('userToken');

  token = localStorage.getItem('userToken');
  headers = new HttpHeaders({
    'Content-Type': 'text/plain',
    'Access-Control-Allow-Origin': '*',
    'Access-token': `${this.token}`,
  });

  httpOpts = { headers: this.headers };
  constructor(private http: HttpClient) {}
  fetchCategDro() {
    return this.http.post(
      `${environment.baseUrl}/v1/v/product_category`,
      '',
      this.httpOpts
    );
  }
  productCategDrp() {
    return this.http.post(
      `${environment.baseUrl}/v1/v/get_product_category`,
      '',
      this.httpOpts
    );
  }
  fetchAttr() {
    return this.http.get(
      `${environment.baseUrl}/v1/v/attribute_value`,
      this.httpOpts
    );
  }
  fetchAttrValue(data: any) {
    return this.http.get(
      `${environment.baseUrl}/v1/v/attribute_value/${data}`,
      this.httpOpts
    );
  }
  uomList() {
    return this.http.post(
      `${environment.baseUrl}/v1/v/get_uom_list`,
      '',
      this.httpOpts
    );
  }
  getDashboardData(data: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/vendor_dashboard`,
      data,
      this.httpOpts
    );
  }
  getRibbon() {
    return this.http.get(
      `${environment.baseUrl}/v1/v/product.ribbon`,
      this.httpOpts
    );
  }
  getTaxList() {
    return this.http.get(
      `${environment.baseUrl}/v1/v/account.tax`,
      this.httpOpts
    );
  }

  getOrderPending(pageNo: number) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/picking_detail_view?status=draft&page=${pageNo}`,
      '',
      this.httpOpts
    );
  }
  getOrderDispached(pageNo: number) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/picking_detail_view?status=in_transit&page=${pageNo}`,
      '',
      this.httpOpts
    );
  }
  getOrderShipped(pageNo: number) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/picking_detail_view?status=shipped&page=${pageNo}`,
      '',
      this.httpOpts
    );
  }
  getOrderCancel(pageNo: number) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/picking_detail_view?status=cancel&page=${pageNo}`,
      '',
      this.httpOpts
    );
  }

  getOrderPlaced(pageNo: number) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/picking_detail_view?status=ordered&page=${pageNo}`,
      '',
      this.httpOpts
    );
  }

  getOrderDelivered(pageNo: number) {
    // return this.http.post(`${environment.baseUrl}/v1/v/sale_order_list_view?status=delivered&page${pageNo}`, '', this.httpOpts)
    return this.http.post(
      `${environment.baseUrl}/v1/v/picking_detail_view?status=delivered&page=${pageNo}`,
      '',
      this.httpOpts
    );
  }

  getOrderDetails(id: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/sale_order_line_details/${id}`,
      '',
      this.httpOpts
    );
  }
  getProductListing() {
    return this.http.post(
      `${environment.baseUrl}/v1/v/product.product.list`,
      '',
      this.httpOpts
    );
  }

  get_picking_address() {
    return this.http.get(
      `${environment.baseUrl}/v1/v/get_picking_address`,
      this.httpOpts
    );
  }

  getActiveProductStockList(pageNo: number) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/product_stock_list?page=${pageNo}`,
      '',
      this.httpOpts
    );
  }
  getPendingProductStockList(pageNo: number) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/product_stock_list?status=requested&page=${pageNo}`,
      '',
      this.httpOpts
    );
  }
  getApprovedProductStockList(pageNo: number) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/product_stock_list?status=approved&page=${pageNo} `,
      '',
      this.httpOpts
    );
  }
  getRejectedProductStockList(pageNo: number) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/product_stock_list?status=rejected&page=${pageNo} `,
      '',
      this.httpOpts
    );
  }
  productStockUpdate(data: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/product_stock_update`,
      data,
      this.httpOpts
    );
  }

  getAllCatalogue(pageNo: number) {
    this.user_token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.user_token);
    return this.http.post(
      `${environment.baseUrl}/v1/v/product.template.view?orderBy=new&page=${pageNo}`,
      '',
      { headers: headers }
    );
  }
  getQcProgressCatalogue(pageNo: any) {
    this.user_token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.user_token);
    return this.http.post(
      `${environment.baseUrl}/v1/v/product.template.view?status=pending&page=${pageNo}`,
      '',
      { headers: headers }
    );
  }
  getQcErrorProducts(pageNo: number) {
    this.user_token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.user_token);
    return this.http.post(
      `${environment.baseUrl}/v1/v/product.template.view?status=rejected&page=${pageNo}`,
      '',
      { headers: headers }
    );
  }
  getLiveProducts(pageNo: number) {
    this.user_token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.user_token);
    return this.http.post(
      `${environment.baseUrl}/v1/v/product.template.view?status=approved&page=${pageNo}`,
      '',
      { headers: headers }
    );
  }

  getArchiveProducts(pageNo: number) {
    this.user_token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.user_token);
    return this.http.post(
      `${environment.baseUrl}/v1/v/product.template.view?archive=1&page=${pageNo}`,
      '',
      { headers: headers }
    );
  }

  uploadProductImage(formData: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      'access-token',
      localStorage.getItem('userToken') || ''
    );
    return this.http.post(
      `${environment.baseUrl}/v1/c/image_upload_s3`,
      formData,
      { headers: headers }
    );
  }

  upload_csv_file(formData: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      'access-token',
      localStorage.getItem('userToken') || ''
    );
    return this.http.post(
      `${environment.baseUrl}/v1/c/upload_csv_file`,
      formData,
      { headers: headers }
    );
  }

  upload_excel_file_for_product(formData: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      'access-token',
      localStorage.getItem('userToken') || ''
    );
    return this.http.post(
      `${environment.baseUrl}/v1/c/upload_excel_file_for_product`,
      formData,
      { headers: headers }
    );
  }

  link_image_with_product(formData: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      'access-token',
      localStorage.getItem('userToken') || ''
    );
    return this.http.post(
      `${environment.baseUrl}/v1/c/link_image_with_product`,
      formData,
      { headers: headers }
    );
  }

  getProductImage(data: any) {
    let body = {
      image: JSON.parse(localStorage.getItem('singleImage') || ''),
      product_id: data,
      image_type: 'base_image',
    };
    return this.http.post(
      `${environment.baseUrl}/v1/c/pando.images`,
      body,
      this.httpOpts
    );
  }
  getProductMultiImage(data: any) {
    let req = {
      image: JSON.parse(localStorage.getItem('multiImage') || ''),
      product_id: data,
      image_type: 'multi_image',
    };
    return this.http.post(
      `${environment.baseUrl}/v1/c/pando.images`,
      req,
      this.httpOpts
    );
  }

  deleteSingleImage() {
    let body = {
      filename: JSON.parse(localStorage.getItem('singleImage') || '')[0].name,
    };
    return this.http.post(
      `${environment.baseUrl}/v1/c/image_upload_s3_delete`,
      body,
      this.httpOpts
    );
  }
  deletemultiImage(resImage: any) {
    let body = {
      filename: resImage,
    };
    return this.http.post(
      `${environment.baseUrl}/v1/c/image_upload_s3_delete`,
      body,
      this.httpOpts
    );
  }

  getNotificationList() {
    return this.http.get(
      `${environment.baseUrl}/v1/c/product.notifications`,
      this.httpOpts
    );
  }

  getNotificationById(id: any) {
    return this.http.get(
      `${environment.baseUrl}/v1/c/product.notifications/${id}`,
      this.httpOpts
    );
  }

  putNotificationById(id: any) {
    return this.http.put(
      `${environment.baseUrl}/v1/c/product.notifications.update/${id}`,
      '',
      this.httpOpts
    );
  }

  allgetNotification() {
    return this.http.get(
      `${environment.baseUrl}/v1/c/product.notifications?SeeAll=True`,
      this.httpOpts
    );
  }

  getTicateDetails(pageNo: number) {
    return this.http.get(
      `${environment.baseUrl}/v1/c/getticket?user_type=vendor&page=${pageNo}`,
      this.httpOpts
    );
  }

  getTicketDetailsById(id: any) {
    return this.http.get(
      `${environment.baseUrl}/v1/c/getticket/${id}`,
      this.httpOpts
    );
  }

  getCustomerTicateDetails(pageNo: number) {
    return this.http.get(
      `${environment.baseUrl}/v1/c/getCustomerTicket?page=${pageNo}`,
      this.httpOpts
    );
  }

  getTicketType() {
    return this.http.get(
      `${environment.baseUrl}/v1/c/getTicketType`,
      this.httpOpts
    );
  }

  getAssignedTicketTo() {
    return this.http.get(
      `${environment.baseUrl}/v1/c/getAssignedTo`,
      this.httpOpts
    );
  }

  saleOrderList(pageNo: number, product_name?: string, data?: any) {
    let params: string;
    if (product_name) {
      params = `page=${pageNo}&search=${product_name}`;
    } else {
      params = `page=${pageNo}`;
    }
    return this.http.post(
      `${environment.baseUrl}/v1/v/sale_order_list_view?${params}`,
      data,
      this.httpOpts
    );
  }

  saleOrderListbyDate(pageNo: number, data: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/sale_order_list_view?page=${pageNo}`,
      data,
      this.httpOpts
    );
  }

  getProductList() {
    return this.http.post(
      `${environment.baseUrl}/v1/v/product.template.view`,
      '',
      this.httpOpts
    );
  }
  getProductDetails(id: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/product.template.view/${id}`,
      '',
      this.httpOpts
    );
  }

  postTicket(data: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/vendor/createticket`,
      data,
      this.httpOpts
    );
  }
  getAttributeDetails(data: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/attribute_value_data`,
      data,
      this.httpOpts
    );
  }
  getProductGraphData(data: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/product.graph.data`,
      data,
      this.httpOpts
    );
  }

  getProductStatus(id: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/product.status`,
      { product_id: id },
      this.httpOpts
    );
  }
  getSaleOrderByMonth(pageNo: number, req: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/sale_order_list_view?page=${pageNo}&quator=${req}`,
      '',
      this.httpOpts
    );
  }
  updateProductDetails(id: any, data: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/product_product_update/${id}`,
      data,
      this.httpOpts
    );
  }
  updateShippingStatus(shipping_status: any, id: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/update_picking_status/${id}?status=${shipping_status}`,
      '',
      this.httpOpts
    );
  }
  genrateInvoiceReport(req: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/generate_invoice_report?quator=${req}`,
      '',
      this.httpOpts
    );
  }
  genrateStockReport(req: any) {
    if (req == 'All') {
      return this.http.post(
        `${environment.baseUrl}/v1/v/generate_stock_report`,
        '',
        this.httpOpts
      );
    } else {
      return this.http.post(
        `${environment.baseUrl}/v1/v/generate_stock_report/${req}`,
        '',
        this.httpOpts
      );
    }
  }
  getReturnTicket(state: any, page: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/get_return_order?state=${state}&page=${page}`,
      '',
      this.httpOpts
    );
  }
  updateReturnStatus(shipping_status: any, id: any) {
    let body = {
      return_id: id,
      state: shipping_status,
    };
    return this.http.post(
      `${environment.baseUrl}/v1/v/update_return_order`,
      body,
      this.httpOpts
    );
  }

  getOrderReturn(pageNo: number, data: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/get_return_order?page=${pageNo}`,
      data,
      this.httpOpts
    );
  }

  postTrackingData(body: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/create.delivery.tracking`,
      body,
      this.httpOpts
    );
  }
  // getOrderList() {
  //   return this.http.get(`${environment.baseUrl}/v1/v/sale.order`, this.httpOpts)
  // }
  getSelectionList() {
    return this.http.get(
      `${environment.baseUrl}/v1/v/delivery.tracking`,
      this.httpOpts
    );
  }
  getDeliveryDetails(id: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/get.delivery.tracking/${id}?user`,
      '',
      this.httpOpts
    );
  }
  addDeliveryline(data: any) {
    let id = JSON.parse(localStorage.getItem('tracking_id') || '');
    return this.http.post(
      `${environment.baseUrl}/v1/c/add.delivery.line/${id}`,
      data,
      this.httpOpts
    );
  }

  deleteBaseImage(resImage: any) {
    let body = {
      filename: resImage,
    };
    return this.http.post(
      `${environment.baseUrl}/v1/c/image_upload_s3_delete`,
      body,
      this.httpOpts
    );
  }
  pando_images_updates(data: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/pando.images.update`,
      data,
      this.httpOpts
    );
  }
  changePassword(data: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/change_password`,
      data,
      this.httpOpts
    );
  }

  getSocialMediaLink() {
    let headers = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Content-Type', 'text/plain');
    return this.http.get(`${environment.baseUrl}/v1/c/social.media.links`, {
      headers: headers,
    });
  }

  createQuotes(body: any) {
    this.user_token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.user_token);
    return this.http.post(`${environment.baseUrl}/v1/c/create_quotes`, body, {
      headers: headers,
    });
  }

  getQuotes(id: any) {
    return this.http.get(
      `${environment.baseUrl}/v1/c/get_quotes/${id}`,
      this.httpOpts
    );
  }
  createShipment(req: any) {
    this.user_token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.user_token);
    return this.http.post(`${environment.baseUrl}/v1/c/create_shipment`, req, {
      headers: headers,
    });
  }

  getPublicCategorty(pageNo: number) {
    return this.http.get(
      `${environment.baseUrl}/v1/c/product.public.category/all?page=${pageNo}`,
      this.httpOpts
    );
  }

  createCategory(body: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/create.product.public.category`,
      body,
      this.httpOpts
    );
  }

  getRegenerateStripeUrl() {
    return this.http.get(
      `${environment.baseUrl}/v1/v/generate_stripe_url`,
      this.httpOpts
    );
  }

  getTemplateList() {
    return this.http.post(
      `${environment.baseUrl}/v1/c/get_template_list`,
      '',
      this.httpOpts
    );
  }

  getProductVarientList() {
    return this.http.get(
      `${environment.baseUrl}/v1/c/get_product_list`,
      this.httpOpts
    );
  }

  CreatePriceList(body: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/create_price_list`,
      body,
      this.httpOpts
    );
  }

  getPriceList(pageNo: number) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/get_price_list?page=${pageNo}`,
      '',
      this.httpOpts
    );
  }

  deletePriceList(id: any) {
    return this.http.get(
      `${environment.baseUrl}/v1/c/delete_price_list/${id}`,
      this.httpOpts
    );
  }

  getPriceListById(id: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/get_price_list/${id}`,
      '',
      this.httpOpts
    );
  }

  updatePriceList(body: any, id: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/update_price_list/${id}`,
      body,
      this.httpOpts
    );
  }

  generateStripeUrl() {
    return this.http.get(
      `${environment.baseUrl}/v1/v/generate_stripe_url`,
      this.httpOpts
    );
  }
  getStripeAccount() {
    return this.http.get(
      `${environment.baseUrl}/v1/v/get_stripe_account`,
      this.httpOpts
    );
  }

  addStripeAccount() {
    return this.http.get(
      `${environment.baseUrl}/v1/v/add_stripe_account`,
      this.httpOpts
    );
  }

  updateStripeAccount(id: any) {
    return this.http.get(
      `${environment.baseUrl}/v1/v/update_stripe_account/${id}`,
      this.httpOpts
    );
  }

  verifyStripeUrl(id: any) {
    return this.http.get(
      `${environment.baseUrl}/v1/v/generate_stripe_url/${id}`,
      this.httpOpts
    );
  }

  getStripeBalanceUrl() {
    return this.http.get(
      `${environment.baseUrl}/v1/v/get_stripe_balance_url`,
      this.httpOpts
    );
  }

  getShippingIds() {
    return this.http.post(
      `${environment.baseUrl}/v1/c/get_shipping_ids`,
      '',
      this.httpOpts
    );
  }

  getShippingDetails(shipping_id: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/get_shipping_details/${shipping_id}`,
      '',
      this.httpOpts
    );
  }

  updateQualityCheck(body: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/update_quality_check`,
      body,
      this.httpOpts
    );
  }

  requestForPayout(body: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/request_for_payout`,
      body,
      this.httpOpts
    );
  }

  getRequestForPayout() {
    return this.http.get(
      `${environment.baseUrl}/v1/c/get_request_for_payout`,
      this.httpOpts
    );
  }

  createLinkToken() {
    return this.http.get(
      `${environment.baseUrl}/v1/c/create_link_token`,
      this.httpOpts
    );
  }

  getProductPickingWise(id: any, page: number) {
    return this.http.get(
      `${environment.baseUrl}/v1/v/get_product_picking_wise/${id}?page=${page}`,
      this.httpOpts
    );
  }

  tokenExchange(body: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/token_exchange`,
      body,
      this.httpOpts
    );
  }

  create_shipments(body: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/create_shipments`,
      body,
      this.httpOpts
    );
  }

  get_shipment_price(body: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/get_shipment_price`,
      body,
      this.httpOpts
    );
  }

  confirm_delivery_for_no_shipment(body: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/confirm_delivery_for_no_shipment`,
      body,
      this.httpOpts
    );
  }

  get_bill_of_lading(body: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/get_bill_of_lading`,
      body,
      this.httpOpts
    );
  }

  tracking_details(body: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/tracking_details`,
      body,
      this.httpOpts
    );
  }

  export_csv_file(id: number) {
    return this.http.post(
      `${environment.baseUrl}/v1/c/export_csv_file?warehouse=${id}`,
      '',
      this.httpOpts
    );
  }

  upload_csv_for_product_with_initial_stock_file(formData: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      'access-token',
      localStorage.getItem('userToken') || ''
    );
    return this.http.post(
      `${environment.baseUrl}/v1/c/upload_csv_for_product_with_initial_stock_file`,
      formData,
      { headers: headers }
    );
  }

  create_and_update_product_with_initial_stock_file(formData: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      'access-token',
      localStorage.getItem('userToken') || ''
    );
    return this.http.post(
      `${environment.baseUrl}/v1/c/create_and_update_product_with_initial_stock_file`,
      formData,
      { headers: headers }
    );
  }

  toggale_product(formData: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/toggale_product`,
      formData,
      this.httpOpts
    );
  }

  toggale_free_shipping() {
    return this.http.get(
      `${environment.baseUrl}/v1/v/toggale_free_shipping`,
      this.httpOpts
    );
  }

  getSaleOrderDetails(id: any) {
    return this.http.get(
      `${environment.baseUrl}/v1/c/sale_order_details/${id}`,
      this.httpOpts
    );
  }
}
