import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  AbstractControl,
  FormGroup,
  Validators,
  ValidatorFn,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxOtpInputConfig } from 'ngx-otp-input/lib/component/ngx-otp-input.model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MustMatch } from 'src/app/shared/helpers/must-match.validator';
import { ProgressComponent } from '../progress/progress.component';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';

import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { ApiService } from 'src/app/shared/services/api.service';
import { environment } from 'src/environments/environment';
declare const google: any;
declare var Plaid: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  newToken: any;
  publicToken: any = '';

  @ViewChild('parent', { static: true })
  parent!: ElementRef;
  @ViewChild('myDiv')
  myDiv!: ElementRef;
  //1
  zipcode: any = '';
  secondAddress: any = '';
  City: any = '';
  addressFirst: any = '';
  streetNumber: any = '';
  State: any = '';
  Country: any = '';
  sublocality_level_2: any = '';
  options: any = {
    componentRestrictions: { country: ['US'] },
  };
  latitude: any;
  longitude: any;
  place_id: any;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective | undefined;

  public handleAddressChange(address: any) {
    this.zipcode = '';
    this.secondAddress = '';
    this.City = '';
    this.addressFirst = '';
    this.streetNumber = '';
    this.State = '';
    this.Country = '';
    this.sublocality_level_2 = '';
    this.latitude = '';
    this.longitude = '';
    this.place_id = '';
    this.supplierForm.get('supplier_state_id')?.setValue('');
    this.supplierForm.get('supplier_address')?.setValue('');
    this.supplierForm.get('supplier_city')?.setValue('');
    this.supplierForm.get('supplier_zip')?.setValue('');
    this.supplierForm.get('supplier_country_id')?.setValue('');
    this.supplierForm.get('supplier_address2')?.setValue('');
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
    this.place_id = address.place_id;

    let div: HTMLDivElement = document.createElement('div');
    div.innerHTML = address?.adr_address;
    this.addressFirst = div.querySelector('.street-address')?.innerHTML;
    this.City = div.querySelector('.locality')?.innerHTML;
    this.State = div.querySelector('.region')?.innerHTML;
    this.supplierForm.patchValue({
      supplier_state_id: this.State,
      bussiness_address: this.addressFirst,
      supplier_city: this.City,
    });

    for (let component of address.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'postal_code': {
          this.zipcode = component.short_name;
          this.supplierForm.patchValue({
            supplier_zip: this.zipcode,
          });
          break;
        }
        case 'country': {
          this.Country = component.short_name;
          this.supplierForm.patchValue({
            supplier_country_id: this.Country,
          });
          break;
        }
      }
    }

    // let ad = address.formatted_address;
    // this.supplierForm.patchValue({
    //   supplier_address: `${ad}`,
    // });
  }
  //1

  //2
  zipcode2: any = '';
  secondAddress2: any = '';
  City2: any = '';
  addressFirst2: any = '';
  streetNumber2: any = '';
  State2: any = '';
  Country2: any = '';
  sublocality_level_22: any = '';
  // options: any = {
  //   componentRestrictions: { country: ['IN', 'US'] },
  // };
  latitude2: any;
  longitude2: any;
  place_id2: any;
  @ViewChild('placesRef2') placesRef2: GooglePlaceDirective | undefined;

  public handleAddressChange2(address: any) {
    this.zipcode2 = '';
    this.secondAddress2 = '';
    this.City2 = '';
    this.addressFirst2 = '';
    this.streetNumber2 = '';
    this.State2 = '';
    this.Country2 = '';
    this.sublocality_level_22 = '';
    this.latitude2 = '';
    this.longitude2 = '';
    this.place_id2 = '';
    this.supplierForm.get('bussiness_address')?.setValue('');
    this.supplierForm.get('bussiness_city')?.setValue('');
    this.supplierForm.get('bussiness_address2')?.setValue('');
    this.supplierForm.get('bussiness_zip')?.setValue('');
    this.supplierForm.get('bussiness_country_id')?.setValue('');
    this.supplierForm.get('bussiness_state_id')?.setValue('');
    this.latitude2 = address.geometry.location.lat();
    this.longitude2 = address.geometry.location.lng();
    this.place_id2 = address.place_id;

    let div: HTMLDivElement = document.createElement('div');
    div.innerHTML = address?.adr_address;
    this.addressFirst2 = div.querySelector('.street-address')?.innerHTML;
    this.City2 = div.querySelector('.locality')?.innerHTML;
    this.State2 = div.querySelector('.region')?.innerHTML;
    this.supplierForm.patchValue({
      bussiness_state_id: this.State2,
      bussiness_address: this.addressFirst2,
      bussiness_city: this.City2,
    });

    for (let component of address.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'postal_code': {
          this.zipcode2 = component.short_name;
          this.supplierForm.patchValue({
            bussiness_zip: this.zipcode2,
          });
          break;
        }
        case 'country': {
          this.Country2 = component.short_name;
          this.supplierForm.patchValue({
            bussiness_country_id: this.Country2,
          });
          break;
        }
      }
    }
  }
  //2

  stepOneForm = true;
  stepTwoForm = false;
  stepThreeForm = false;
  signupForm: FormGroup;
  submitted = false;
  otpVerification = false;
  otp: any;
  step = 1;
  a_password: string = '';
  c_password: string = '';
  toggle1: boolean = false;
  toggle2: boolean = false;
  supplierForm!: FormGroup;
  countries: any;
  scountries: any;
  Pickupcountries: any;
  states: any;
  Pickupstates: any;
  sstates: any;
  ssubmitted = false;
  activeIndex = 1;
  scid: any;
  submitteds1 = false;
  submitteds2 = false;
  submitteds3 = false;
  submitteds4 = false;
  checkToken: any;
  vendorAddress: any;
  selectedCountryCode = 'us';
  isShowPreButton: boolean = true;
  isSignup: boolean = false;

  profile_step = Number(localStorage.getItem('pofile_step'));
  testForm = new FormGroup({
    food: new FormControl('', Validators.required),
    comment: new FormControl('', Validators.required),
  });

  separateDialCode: any;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  constructor(
    private router: Router,
    private _FormBuilder: FormBuilder,
    private _ToastrService: ToastrService,
    private _AuthService: AuthService,
    private renderer: Renderer2,
    private apiService: ApiService
  ) {
    this.signupForm = this._FormBuilder.group(
      {
        name: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.maxLength(25),
              Validators.minLength(2),
              Validators.pattern('^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$'),
            ]),
          ],
        ],
        last_name: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.maxLength(25),
              Validators.minLength(2),
              Validators.pattern('^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$'),
            ]),
          ],
        ],
        email: [
          '',
          [
            Validators.required,
            Validators.maxLength(30),
            Validators.pattern(
              /^[a-zA-Z]+[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z.]{2,5}$/
            ),
          ],
        ],
        phone: ['', [Validators.required]],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:{\\}\\[\\]\\|\\+\\-\\=\\_\\)\\(\\)\\`\\/\\\\\\]])[A-Za-z0-9d$@].{7,}'
            ),
          ],
        ],
        confirm_password: ['', [Validators.required]],
        otp: [''],
      },
      {
        validator: MustMatch('password', 'confirm_password'),
      }
    );
  }

  changeType(input_field_password: any, num: number) {
    if (input_field_password.type == 'password')
      input_field_password.type = 'text';
    else input_field_password.type = 'password';

    if (num == 1) this.toggle1 = !this.toggle1;
    else this.toggle2 = !this.toggle2;
  }

  get f() {
    return this.signupForm.controls;
  }

  ngOnInit(): void {
    this.checkToken = localStorage.getItem('userToken');
    this.fetchCountry();
    this.fetchPickupCountry();
    this.getVendorDetails();
    this.fetchState(233);

    this.supplierForm = this._FormBuilder.group(
      {
        bank_type: ['STRIPE'],

        account_name: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.maxLength(100),
              Validators.minLength(2),
              Validators.pattern('^[a-zA-Z ]*$'),
            ]),
          ],
        ],
        bank_name: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.maxLength(100),
              Validators.minLength(2),
              Validators.pattern('^[a-zA-Z ]*$'),
            ]),
          ],
        ],
        account_number: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.minLength(8),
              Validators.maxLength(12),
              Validators.pattern('[0-9]+'),
            ]),
          ],
        ],
        con_account_number: ['', [Validators.required]],
        ein_number: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.minLength(10),
              Validators.maxLength(15),
            ]),
          ],
        ],
        routing_no: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(11),
              Validators.pattern('[0-9]+'),
            ]),
          ],
        ],
        // owner_name: ['', [Validators.required, Validators.pattern(/^.{16,500}$/)]],
        owner_name: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.maxLength(50),
              Validators.minLength(2),
            ]),
          ],
        ],
        business_name: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.maxLength(100),
              Validators.minLength(2),
            ]),
          ],
        ],
        is_owner: [true, [Validators.required]],
        supplier_country_id: ['', [Validators.required]],
        supplier_state_id: ['', [Validators.required]],
        supplier_zip: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(7),
              Validators.pattern('[0-9]+'),
            ]),
          ],
        ],
        supplier_phone: ['', [Validators.required]],
        supplier_address: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.maxLength(500),
            ]),
          ],
        ],
        supplier_address2: [''],
        supplier_city: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(100),
            ]),
          ],
        ],
        bussiness_country_id: ['', [Validators.required]],
        bussiness_state_id: ['', [Validators.required]],
        bussiness_zip: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(7),
              Validators.pattern('[0-9]+'),
            ]),
          ],
        ],
        bussiness_city: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.maxLength(100),
              Validators.minLength(2),
            ]),
          ],
        ],
        bussiness_address: ['', [Validators.compose([Validators.required])]],
        bussiness_address2: [''],
      },
      {
        validator: MustMatch('account_number', 'con_account_number'),
      }
    );

    if (this.profile_step && this.profile_step < 4) {
      if (this.profile_step > 0) {
        this.getVendorDetails();
        this.createLinkToken();
      }
      this.activeIndex = this.profile_step;
      this.submitted = true;
      this.stepOneForm = false;
      this.stepTwoForm = true;
    }
  }

  countryChange($event: any) {
    this.selectedCountryCode = $event.iso2;
    this.supplierForm.controls['supplier_phone'].setValue('');
  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }

  getVendorDetails() {
    this._AuthService.getVendorInfo().subscribe((res: any) => {
      if (res?.status == 200) {
        this.vendorAddress = res?.record;
        // this.fetchState(res?.record.bussiness_country_id ? res?.record.bussiness_country_id : res?.record.supplier_state_id);
        this.latitude2 = res?.record?.partner_details.lat_coordinates;
        this.longitude2 = res?.record?.partner_details.long_coordinates;
        // this.place_id2 =
        this.latitude = res?.record?.supplier_lat_coordinates;
        this.longitude = res?.record?.supplier_long_coordinates;
        this.place_id = res?.record?.place_id;
        this.place_id2 = res?.record?.bussiness_place_id;
        this.supplierForm.patchValue({
          bank_name: res?.record.bank_name || '',
          routing_no: res?.record.routing_no,
          account_name: res?.record.account_name || '',
          account_number: res?.record.account_number || '',
          con_account_number: res?.record.account_number || '',
          ein_number: res?.record.gst_number || '',
          bussiness_zip: res?.record.bussiness_zip,
          business_name: res?.record.business_name,
          owner_name: res?.record.owner_name,
          bussiness_country_id: res?.record.bussiness_country,
          bussiness_state_id: res?.record.bussiness_state,
          bussiness_address: res?.record.bussiness_address,
          bussiness_city: res?.record.bussiness_city,
          supplier_city: res?.record.supplier_city,
          supplier_country_id: res?.record.supplier_country,
          supplier_state_id: res?.record.supplier_state,
          supplier_phone: res?.record.supplier_phone || '',
          supplier_address: res?.record.supplier_address,
          supplier_zip: res?.record.supplier_zip,
          is_owner: true,
        });
      }
    });
  }

  spaceRemoveFirst(e: any) {
    if (e.target.selectionStart == 0 && e.code === 'Space') {
      e.preventDefault();
    }
  }

  fetchCountry() {
    this._AuthService.getCountry().subscribe((res: any) => {
      if (res && res.isSucess) {
        this.countries = res.result;
      }
    });
  }
  fetchPickupCountry() {
    this._AuthService.getCountry().subscribe((data: any) => {
      if (data && data.isSucess) {
        this.Pickupcountries = data.result;
      }
    });
  }

  signUp() {
    if (!this.otpVerification) {
      let body = {
        name: this.signupForm.get('name')?.value,
        last_name: this.signupForm.get('last_name')?.value,
        email: this.signupForm.get('email')?.value,
      };
      this._AuthService.otpVerify(body).subscribe(
        (res: any) => {
          if (res.status_code) this.otpVerification = true;
          this._ToastrService.success(res.message);
        },
        (error: any) => {
          this._ToastrService.error(error.error.message);
        }
      );
    } else {
      let { confirm_password, email, last_name, name, otp, password, phone } =
        this.signupForm.value;
      let signup_phone = phone.number;
      let body = {
        confirm_password,
        email,
        last_name,
        name,
        otp,
        password,
        phone: signup_phone,
      };

      this._AuthService.SignUp(body).subscribe(
        (res: any) => {
          if (res.status_code == 200) {
            this.submitted = true;
            this._ToastrService.success(res.message);
            if (this.signupForm.valid) {
              this.stepOneForm = false;
              this.stepTwoForm = true;
            }

            let body1 = {
              login: this.signupForm.controls.email.value,
              db: environment.db_name,
              password: this.signupForm.controls.password.value,
            };
            this._AuthService.login(body1).subscribe(
              (res: any) => {
                localStorage.setItem('pofile_step', res.profile_step);
                localStorage.setItem('userToken', res.access_token);
                localStorage.setItem('partnerId', res.partner_id);
                localStorage.setItem('name', res.name);
                localStorage.setItem('vendorImages', res.image);
                this.router.navigate(['/create-supplier-account']);
                this.getVendorDetails();
                window.location.reload();
              },
              (error: any) => {
                this._ToastrService.error(error.error.message);
              }
            );
          }
        },
        (error: any) => {
          this._ToastrService.error(error.error.message);
        }
      );
    }
  }

  previousstep() {
    this.stepOneForm = true;
    this.stepTwoForm = false;
  }

  alldetails() {
    this.stepTwoForm = false;
    this.stepThreeForm = true;
  }

  previous() {
    this.stepTwoForm = true;
    this.stepThreeForm = false;
  }

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };

  handeOtpChange(value: string[]): void {}

  handleFillEvent(value: string): void {
    this.signupForm.get('otp')?.setValue(value);
    this.otp = value;
  }

  Submit() {
    let body = {
      name: this.signupForm.controls.name.value,
      email: this.signupForm.controls.email.value,
      password: this.signupForm.controls.password.value,
      confirm_password: this.signupForm.controls.confirm_password.value,
      // ein_number: this.supplierForm.controls.ein_number.value,
      // account_name: this.supplierForm.controls.account_name.value,
      // account_number: this.supplierForm.controls.account_number.value,
      // routing_no: this.supplierForm.controls.routing_no.value,
      // owner_name: this.supplierForm.controls.owner_name.value,
      // business_name: this.supplierForm.controls.business_name.value,
      // supplier_country_id: parseInt(this.supplierForm.controls.supplier_country_id.value),
      // supplier_state_id: parseInt(this.supplierForm.controls.supplier_state_id.value),
      // supplier_city: this.supplierForm.controls.supplier_city.value,
      // supplier_phone: this.supplierForm.controls.supplier_phone.value,
      // supplier_address: this.supplierForm.controls.supplier_address.value,
      // country_id: parseInt(this.supplierForm.controls.country_id.value),
      // state_id: parseInt(this.supplierForm.controls.state_id.value),
      // city: this.supplierForm.controls.city.value,
      // address: this.supplierForm.controls.address.value,
      otp: this.otp,
      user_type: 'vendor',
    };
    this._AuthService.SignUp(body).subscribe(
      (data: any) => {
        let status = data.status_code;
        if (status == 200) {
          this._ToastrService.success(data.message);
          this.router.navigate(['/login']);
        } else {
          this._ToastrService.success(data.message);
        }
      },
      (error: any) => {
        this._ToastrService.error(error.error.message);
      }
    );
  }

  // supplierDetails() {
  //   if (this.signupForm.controls.name.valid,
  //     this.signupForm.controls.email.valid,
  //     this.signupForm.controls.password.valid,
  //     this.signupForm.controls.confirm_password.valid,
  //     this.supplierForm.controls.account_name.valid,
  //     this.supplierForm.controls.account_number.valid,
  //     this.supplierForm.controls.routing_no.valid,
  //     this.supplierForm.controls.owner_name.valid,
  //     this.supplierForm.controls.business_name.valid,
  //     this.supplierForm.controls.supplier_country_id.valid,
  //     this.supplierForm.controls.supplier_state_id.valid,
  //     this.supplierForm.controls.supplier_city.valid,
  //     this.supplierForm.controls.supplier_phone.valid,
  //     this.supplierForm.controls.supplier_address.valid,
  //     this.supplierForm.controls.country_id.valid,
  //     this.supplierForm.controls.state_id.valid,
  //     this.supplierForm.controls.city.valid,
  //     this.supplierForm.controls.address.valid) {
  //     let body = {
  //       name: this.signupForm.controls.name.value,
  //       email: this.signupForm.controls.email.value,
  //     };
  //     let req = {
  //       mobile: this.supplierForm.controls.supplier_phone.value.toString(),
  //       country_id: parseInt(this.supplierForm.controls.supplier_country_id.value),
  //     }
  //     this._AuthService.checkMobileNumber(req).subscribe((data: any) => {
  //       if (data.status == 200) {
  //         this._AuthService.otpVerify(body).subscribe((data: any) => {
  //           let status = data.status_code
  //           if (status == 200) {
  //             this._ToastrService.success(data.message);
  //             this.alldetails();
  //           }
  //         },
  //           (error: any) => {
  //             this._ToastrService.error(error.error.message)
  //           }
  //         )
  //       }
  //     },
  //       (error: any) => {
  //         if (error.error.status == 400) {
  //           this._ToastrService.error(error.error.message);
  //         }

  //       })

  //   }
  // }

  get fs() {
    return this.supplierForm.controls;
  }

  goNext(progress: ProgressComponent, step: any) {
    if (step == 'bank_info') {
      if (this.supplierForm.get('bank_type')?.value === 'STRIPE') {
        this.isSignup = true;
        let req = {
          bank_name: this.supplierForm.controls.bank_name.value,
          routing_no: this.supplierForm.controls.routing_no.value,
          account_name: this.supplierForm.controls.account_name.value,
          account_number: this.supplierForm.controls.account_number.value,
        };
        this._AuthService.saveBankDetail(req).subscribe(
          (res: any) => {
            if (res.status_code == 200) {
              this.isShowPreButton = false;
              this.isSignup = false;
              let stripeURL = res?.url;
              window.location.href = stripeURL;
            }
          },
          (error: any) => {
            this._ToastrService.error(error.error.message);
            this.isShowPreButton = true;
            this.isSignup = true;
          }
        );
      } else if (this.supplierForm.get('bank_type')?.value === 'PLAID') {
        this.verifyPlaid();
      }
    } else if (step == 'business_info') {
      let req = {
        bussiness_zip: this.supplierForm.controls.bussiness_zip.value,
        business_name: this.supplierForm.controls.business_name.value,
        owner_name: this.supplierForm.controls.owner_name.value,
        bussiness_country:
          this.supplierForm.controls.bussiness_country_id.value,
        bussiness_address: this.supplierForm.controls.bussiness_address.value,
        bussiness_address2: this.supplierForm.controls.bussiness_address2.value,
        bussiness_city: this.supplierForm.controls.bussiness_city.value,
        bussiness_state: this.supplierForm.controls.bussiness_state_id.value,
        business_lat_coordinates: this.latitude2,
        business_long_coordinates: this.longitude2,
        company_register_number: this.supplierForm.controls.ein_number.value,
        bussiness_place_id: this.place_id2,
      };
      this._AuthService.saveBusinessDetail(req).subscribe(
        (res: any) => {
          if (res.status_code == 200) {
            this._ToastrService.success('Business Info Updated');
            progress.next();
          }
        },
        (error: any) => {
          this._ToastrService.error(error.error.message);
        }
      );
    } else {
      if (!this.place_id) {
        this._ToastrService.error('Address is not Approprite');
        return;
      }

      let phone = this.supplierForm.controls.supplier_phone.value;
      let s_phone = phone.number;
      let req = {
        supplier_country: this.supplierForm.controls.supplier_country_id.value,
        supplier_state: this.supplierForm.controls.supplier_state_id.value,
        supplier_phone: s_phone,
        supplier_city: this.supplierForm.controls.supplier_city.value,
        supplier_address: this.supplierForm.controls.supplier_address.value,
        supplier_zip: this.supplierForm.controls.supplier_zip.value,
        is_owner: this.supplierForm.controls.is_owner.value,
        supplier_long_coordinates: this.longitude,
        supplier_lat_coordinates: this.latitude,
        place_id: this.place_id,
        supplier_address_street2:
          this.supplierForm.controls.supplier_address2.value,
      };
      this._AuthService.saveSupplierDetail(req).subscribe(
        (res: any) => {
          if (res.status_code == 200) {
            this._ToastrService.success('Supplier Details Updated');
            progress.next();
          }
        },
        (error: any) => {
          this._ToastrService.error(error.error.message);
        }
      );
    }
  }

  onStateChange(event: any) {}

  fetchState(event: any) {
    let countryId = event;
    this._AuthService.getState(countryId).subscribe((res: any) => {
      this.states = res.result;
    });
  }

  fetchPickupState(id: any) {
    let countryId = id.target ? id.target.value : id;
    this._AuthService.getState(countryId).subscribe((data: any) => {
      this.Pickupstates = data.result;
    });
  }

  logout() {
    this._AuthService.logOutVendor().subscribe((data: any) => {
      let status = data.status;
      if (status == 200) {
        this.router.navigate(['/login']);
        this._ToastrService.success('You are successfully logOut');
        localStorage.clear();
      }
    });
  }

  ngAfterViewInit() {}

  FormateInput(inputField: HTMLInputElement) {
    let inputVal = inputField.value.replace(/\D/g, '');
    inputVal = inputVal.substring(0, 9);
    if (inputVal.length > 2 && inputVal.charAt(2) !== '-') {
      inputVal = inputVal.slice(0, 2) + '-' + inputVal.slice(2);
    }
    inputField.value = inputVal;
    this.supplierForm.get('ein_number')?.setValue(inputVal);
  }

  handleChange(event: any) {
    if (event.target.checked) {
      let {
        bussiness_address,
        bussiness_address2,
        bussiness_city,
        bussiness_zip,
        bussiness_state_id,
        bussiness_country_id,
      } = this.supplierForm.value;

      this.supplierForm.patchValue({
        supplier_address: bussiness_address,
        supplier_address2: bussiness_address2,
        supplier_city: bussiness_city,
        supplier_zip: bussiness_zip,
        supplier_state_id: bussiness_state_id,
        supplier_country_id: bussiness_country_id,
      });
      this.latitude = this.latitude2;
      this.longitude = this.longitude2;
      this.place_id = this.place_id2;
    } else {
      this.supplierForm.patchValue({
        supplier_address: '',
        supplier_address2: '',
        supplier_city: '',
        supplier_zip: '',
        supplier_state_id: '',
        supplier_country_id: '',
      });
      this.latitude = '';
      this.longitude = '';
      this.place_id = '';
    }
  }

  handle_stripe(event: any, value: any) {
    this.supplierForm.get('account_name')?.enable();
    this.supplierForm.get('account_number')?.enable();
    this.supplierForm.get('routing_no')?.enable();
    this.supplierForm.get('bank_name')?.enable();
    this.supplierForm.get('con_account_number')?.enable();
  }

  handle_plaid(event: any, value: any) {
    this.supplierForm.get('account_name')?.disable();
    this.supplierForm.get('account_number')?.disable();
    this.supplierForm.get('routing_no')?.disable();
    this.supplierForm.get('bank_name')?.disable();
    this.supplierForm.get('con_account_number')?.disable();
    this.supplierForm.get('account_number')?.setValue('');
    this.supplierForm.get('routing_no')?.setValue('');
    this.supplierForm.get('bank_name')?.setValue('');
    this.supplierForm.get('con_account_number')?.setValue('');
  }

  public tokenExchange() {
    let publicToken = localStorage.getItem('Public_Token');
    let accountID = localStorage.getItem('account_ID');
    let req = {
      public_token: publicToken,
      account_id: accountID,
    };
    this.apiService.tokenExchange(req).subscribe(
      (res: any) => {
        if (res?.isSucess) {
          this._ToastrService.success(res.message);
          window.open(res.url, '_self');
        }
      },
      (error: any) => {
        this._ToastrService.error(error.error.message);
      }
    );
  }

  createLinkToken() {
    this.apiService.createLinkToken().subscribe(
      (res: any) => {
        if (res?.isSucess) {
          this.newToken = res?.data?.link_token;
        }
      },
      (error: any) => {
        this._ToastrService.error(error.error.message);
      }
    );
  }

  verifyPlaid() {
    const configs = {
      // Pass the link_token generated in step 2.
      token: this.newToken,
      onLoad: function () {
        // The Link module finished loading.
      },
      onSuccess: (
        public_token: string,
        metadata: { accounts: string | any[] }
      ) => {
        // The onSuccess function is called when the user has
        // successfully authenticated and selected an account to
        // use.
        //
        // When called, you will send the public_token
        // and the selected account ID, metadata.accounts,
        // to your backend app server.
        //
        // sendDataToBackendServer({
        //   public_token: public_token,
        //   account_id: metadata.accounts[0].id
        // });

        localStorage.setItem('Public_Token', public_token);
        switch (metadata.accounts.length) {
          case 0:
            // Select Account is disabled: https://dashboard.plaid.com/link/account-select
            break;
          case 1:
            localStorage.setItem('account_ID', metadata.accounts[0].id);
            break;
          default:
          // Multiple Accounts is enabled: https://dashboard.plaid.com/link/account-select
        }

        this.tokenExchange();
      },

      onExit: async function (err: null, metadata: any) {
        // The user exited the Link flow.
        if (err != null) {
          // The user encountered a Plaid API error
          // prior to exiting.
        }
        // metadata contains information about the institution
        // that the user selected and the most recent
        // API request IDs.
        // Storing this information can be helpful for support.
      },
    };

    var linkHandler = Plaid.create(configs);
    linkHandler.open();

    // document.getElementById('link-button').onclick = function() {
    //   linkHandler.open();
    // };
  }

  onInputChange(event: any) {
    const inputValue = event.target.value;
    const firstChar = inputValue.charAt(0);
    if (firstChar === '0' || firstChar === '1') {
      this._ToastrService.info('The Number should not start With 0 and 1');
      event.target.value = inputValue.substring(1); // Remove the leading zero or one
    }
  }
}
