<!-- header -->
<header>
  <div
    class="header container-fluid d-flex justify-content-between align-items-center"
  >
    <div>
      <a href="#"
        ><img
          class="logo-mobile"
          src="assets/images/Hatrik-logo.png"
          width="97"
          height="45"
          alt=""
      /></a>
    </div>
    <div class="ml-auto mr-3">
      <button
        type="button"
        class="btn btn-outline-primary start-selling"
        routerLink="create-supplier-account"
      >
        Start Selling
      </button>
    </div>
    <div>
      <button
        type="button"
        class="btn btn-outline-light btn-outline-light1"
        routerLink="login"
      >
        Login
      </button>
    </div>
  </div>
</header>

<!-- content -->
<div class="container-fluid">
  <!-- content 1 -->
  <div class="content-1 d-flex flex-column-reverse  flex-md-row justify-content-between">
    <div class="">
      <h1 class="h1-text">
        Grow Your Business With <br />
        the HATRIK Marketplace
      </h1>
      <p class="changes">
        Click on the link below to create your account and start selling your
        medical products on the HATRIK Platform
      </p>
      <button
        routerLink="create-supplier-account"
        type="button"
        class="btn btn-ssn"
      >
        Start Selling Now
      </button>
    </div>

    <div class="landing-image">
      <img src="assets/images/landing-image.png" alt="" />
    </div>
  </div>

  <!-- content 2 -->
  <div class="content-2">
    <div class="d-flex flex-wrap gap-3 justify-content-evenly">
      <div class="d-flex align-items-center">
        <div>
          <img src="assets/images/icons/cont-img-2.1.svg" alt="" />
        </div>
        <div class="pl-3">
          <p class="numeric-data mb-1">0.1 M +</p>
          <p class="text-data mb-0">
            Suppliers selling On <br />
            HATRIK
          </p>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div>
          <img src=" assets/images/icons/cont-img-2.2.svg" alt="" />
        </div>
        <div class="pl-3">
          <p class="numeric-data mb-1">500+</p>
          <p class="text-data mb-0">Categories to sell</p>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div>
          <img src=" assets/images/icons/cont-img-2.3.svg" alt="" />
        </div>
        <div class="pl-3">
          <p class="numeric-data mb-1">100%</p>
          <p class="text-data">
            Secure Payment <br />
            Deposits
          </p>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div>
          <img src=" assets/images/icons/cont-img-2.4.svg" alt="" />
        </div>
        <div class="pl-3">
          <p class="numeric-data mb-1">24/7</p>
          <p class="text-data">
            Customer Support <br />
            Available
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- content-3 -->
  <div class="content-3">
    <h1 class="text-center content-heading">
      Why Suppliers love <span>HATRIK</span> Marketplace
    </h1>

    <div class="row">
      <div class="card1 col">
        <div>
          <img src="assets/images/icons/cont-img-3.1.svg" alt="" />
        </div>

        <h3 class="card-heading1">Easy Cataloging</h3>

        <p class="card-body1">
          Lorem ipsum dolor sit amet, consect etur adipiscing elit. Maecenas
          ullam corper tincidunt dolor sit amet, con
        </p>
      </div>
      <div class="card1 col">
        <div>
          <img src="assets/images/icons/cont-img-3.2.svg" alt="" />
        </div>
        <h3 class="card-heading1">Growth for small suppliers</h3>
        <p class="card-body1">
          Lorem ipsum dolor sit amet, consect etur adipiscing elit. Maecenas
          ullam corper tincidunt dolor sit amet, con
        </p>
      </div>
      <div class="card1 col">
        <div>
          <img src="assets/images/icons/cont-img-3.3.svg" alt="" />
        </div>
        <h3 class="card-heading1">Stress-free shipping</h3>
        <p class="card-body1">
          Lorem ipsum dolor sit amet, consect etur adipiscing elit. Maecenas
          ullam corper tincidunt dolor sit amet, con
        </p>
      </div>
    </div>
  </div>

  <!-- content 4 -->
  <div class="content-4">
    <h1 class="text-center content-heading">
      How to sell on <span>HATRIK</span> Marketplace
    </h1>

    <div class="row sell-process">
      <div class="border-back"></div>
      <div class="col text-center">
        <img src=" assets/images/icons/sell-process-1.svg" alt="" />
        <h3 class="sell-title">Create Account</h3>
        <p class="sell-body">
          Create an account. All you need is bank account and GSTIN number
        </p>
      </div>
      <div class="col text-center">
        <img src=" assets/images/icons/sell-process-2.svg" alt="" />
        <h3 class="sell-title">Upload your products</h3>
        <p class="sell-body">
          After Creating account upload your products catalog on the Panel
        </p>
      </div>
      <div class="col text-center">
        <img src=" assets/images/icons/sell-process-3.svg" alt="" />
        <h3 class="sell-title">Get Orders</h3>
        <p class="sell-body">
          You can Track your Orders on our Centralized dashboard
        </p>
      </div>
      <div class="col text-center">
        <img src=" assets/images/icons/sell-process-4.svg" alt="" />
        <h3 class="sell-title">Ship Orders</h3>
        <p class="sell-body">
          Our Delivery partners will collect your product from your doorstep
        </p>
      </div>
      <div class="col text-center">
        <img src=" assets/images/icons/sell-process-5.svg" alt="" />
        <h3 class="sell-title">Receive Payments</h3>
        <p class="sell-body">
          Payment is deposited to your bank account in 7 day payment cycle
        </p>
      </div>
    </div>
  </div>

  <!-- content 5 -->
  <div class="content-5">
    <div class="content-3">
      <h1 class="text-center content-heading">
        Trusted By Various People & Store Owners
      </h1>

      <div class="row">
        <div class="card1 col">
          <div class="text-center">
            <img src=" assets/images/cont-img-5.1.svg" alt="" />
          </div>
          <h3 class="card-heading1 text-center">Sofia Paul</h3>
          <p class="card-title2 text-center">Pastel Colours</p>
          <p class="card-body2 text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique
            tincidunt duis cursus netus ultrices semper venenatis. A dolor,
            risus, diam at gravida fer
          </p>
        </div>
        <div class="card1 col">
          <div class="text-center">
            <img src=" assets/images/cont-img-5.2.svg" alt="" />
          </div>
          <h3 class="card-heading1 text-center">John</h3>
          <p class="card-title2 text-center">Pastel Colours</p>
          <p class="card-body2 text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique
            tincidunt duis cursus netus ultrices semper venenatis. A dolor,
            risus, diam at gravida fer
          </p>
        </div>
        <div class="card1 col">
          <div class="text-center">
            <img src=" assets/images/cont-img-5.3.svg" alt="" />
          </div>
          <h3 class="card-heading1 text-center">Samsun</h3>
          <p class="card-title2 text-center">Pastel Colours</p>
          <p class="card-body2 text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique
            tincidunt duis cursus netus ultrices semper venenatis. A dolor,
            risus, diam at gravida fer
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- content 6 -->
  <div class="content-6">
    <div class="d-flex flex-column flex-md-row justify-content-between">
      <div>
        <h1 class="heading-avalable">
          <span>HATRIK</span> Marketplace Supplier <br />
          Support
        </h1>
        <p class="available-text">Available 24/7</p>
      </div>
      <div>
        <h2 class="heading-available1">
          Our Supplier support is available to solve all your doubts and issues
          before and after you start your online selling business
        </h2>

        <div class="d-flex align-items-center">
          <img src=" assets/images/Rectangle.svg" alt="" />
          <div class="you-reach">
            <p class="heading-available2">You can reach out to</p>
            <p class="sell">sell@hatrick.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- content 7 -->
  <div class="content-7" id="google">
    <h1 class="text-center content-heading">Frequently asked questions</h1>

    <div class="qa-div-1 mb-3">
      <div class="question-box d-flex" [ngClass]="{ 'open-box': answershow1 }">
        <p class="question">What is <span>HATRIK</span> Marketplace?</p>

        <div class="ml-auto">
          <img
            style="cursor: pointer"
            (click)="on1()"
            *ngIf="!answershow1"
            src="assets/images/icons/answer-show.svg"
            alt=""
          />
          <img
            style="cursor: pointer"
            (click)="answershow1 = !answershow1"
            *ngIf="answershow1"
            src="assets/images/icons/answer-hide.svg"
            alt=""
          />
        </div>
      </div>

      <div *ngIf="answershow1" class="answer-box">
        <p class="answer">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Euismod orci
          gravida ipsum amet nec pulvinar aliquam id. Sit nunc, tortor
          ullamcorper varius. Id vitae sit in ligula non porta ac vel amet.
          Vitae gravida facilisis tortor convallis at tortor, elit aliquet.
          Consectetur lacus, at tristique dui nisl et nibh elit cursus. Nunc
          egestas lorem mollis faucibus sagittis velit dui. Tristique eget
          tempor in sagittis in semper nunc pulvinar varius.
        </p>
      </div>
    </div>

    <div class="qa-div-2 mb-3">
      <div class="question-box d-flex" [ngClass]="{ 'open-box': answershow2 }">
        <p class="question">
          What is Commission charged on <span>HATRIK</span> Marketplace?
        </p>

        <div class="ml-auto">
          <img
            style="cursor: pointer"
            (click)="on2()"
            *ngIf="!answershow2"
            src="assets/images/icons/answer-show.svg"
            alt=""
          />
          <img
            style="cursor: pointer"
            (click)="answershow2 = !answershow2"
            *ngIf="answershow2"
            src="assets/images/icons/answer-hide.svg"
            alt=""
          />
        </div>
      </div>

      <div *ngIf="answershow2" class="answer-box">
        <p class="answer">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Euismod orci
          gravida ipsum amet nec pulvinar aliquam id. Sit nunc, tortor
          ullamcorper varius. Id vitae sit in ligula non porta ac vel amet.
          Vitae gravida facilisis tortor convallis at tortor, elit aliquet.
          Consectetur lacus, at tristique dui nisl et nibh elit cursus. Nunc
          egestas lorem mollis faucibus sagittis velit dui. Tristique eget
          tempor in sagittis in semper nunc pulvinar varius.
        </p>
      </div>
    </div>

    <div class="qa-div-3 mb-3">
      <div class="question-box d-flex" [ngClass]="{ 'open-box': answershow3 }">
        <span class="question"
          >What are the benefits of selling on
          <span>HATRIK</span> Marketplace?</span
        >

        <div class="ml-auto">
          <img
            style="cursor: pointer"
            (click)="on3()"
            *ngIf="!answershow3"
            src="assets/images/icons/answer-show.svg"
            alt=""
          />
          <img
            style="cursor: pointer"
            (click)="answershow3 = !answershow3"
            *ngIf="answershow3"
            src="assets/images/icons/answer-hide.svg"
            alt=""
          />
        </div>
      </div>

      <div *ngIf="answershow3" class="answer-box">
        <span class="answer"
          >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Euismod orci
          gravida ipsum amet nec pulvinar aliquam id. Sit nunc, tortor
          ullamcorper varius. Id vitae sit in ligula non porta ac vel amet.
          Vitae gravida facilisis tortor convallis at tortor, elit aliquet.
          Consectetur lacus, at tristique dui nisl et nibh elit cursus. Nunc
          egestas lorem mollis faucibus sagittis velit dui. Tristique eget
          tempor in sagittis in semper nunc pulvinar varius.
        </span>
      </div>
    </div>

    <div class="qa-div-4 mb-3">
      <div class="question-box d-flex" [ngClass]="{ 'open-box': answershow4 }">
        <span class="question"
          >How to list on <span>HATRIK</span> Marketplace?</span
        >

        <div class="ml-auto">
          <img
            style="cursor: pointer"
            (click)="on4()"
            *ngIf="!answershow4"
            src="assets/images/icons/answer-show.svg"
            alt=""
          />
          <img
            style="cursor: pointer"
            (click)="answershow4 = !answershow4"
            *ngIf="answershow4"
            src="assets/images/icons/answer-hide.svg"
            alt=""
          />
        </div>
      </div>

      <div *ngIf="answershow4" class="answer-box">
        <span class="answer"
          >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Euismod orci
          gravida ipsum amet nec pulvinar aliquam id. Sit nunc, tortor
          ullamcorper varius. Id vitae sit in ligula non porta ac vel amet.
          Vitae gravida facilisis tortor convallis at tortor, elit aliquet.
          Consectetur lacus, at tristique dui nisl et nibh elit cursus. Nunc
          egestas lorem mollis faucibus sagittis velit dui. Tristique eget
          tempor in sagittis in semper nunc pulvinar varius.
        </span>
      </div>
    </div>
  </div>
</div>

<footer>
  <div id="main-footer" class="text-center">
    <p>© Copyright 2022. All Rights Reserved by HATRIK</p>
  </div>
</footer>
