import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termandcondition',
  templateUrl: './termandcondition.component.html',
  styleUrls: ['./termandcondition.component.scss']
})
export class TermandconditionComponent implements OnInit {


  tableOfContents = [
    'AGREEMENT TO TERMS',
    'INTELLECTUAL PROPERTY RIGHTS',
    'USER REPRESENTATIONS',
    'USER REGISTRATION',
    'PRODUCTS',
    'PURCHASES AND PAYMENT',
    'RETURN POLICY',
    'PROHIBITED ACTIVITIES',
    'USER GENERATED CONTRIBUTIONS',
    'CONTRIBUTION LICENCE',
    'GUIDELINES FOR REVIEWS',
    'SUBMISSIONS',
    'THIRD-PARTY WEBSITE AND CONTENT',
    'SITE MANAGEMENT',
    'PRIVACY POLICY',
    'TERM AND TERMINATION',
    'MODIFICATIONS AND INTERRUPTIONS',
    'GOVERNING LAW',
    'DISPUTE RESOLUTION',
    'CORRECTIONS',
    'DISCLAIMER',
    'LIMITATIONS OF LIABILITY',
    'INDEMNIFICATION',
    'USER DATA',
    'ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES',
    'CALIFORNIA USERS AND RESIDENTS',
    'MISCELLANEOUS',
    'CONTACT US'
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
