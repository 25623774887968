<header>
    <div class="header container-fluid d-flex justify-content-between">
        <div class="mt-3">
            <a href="#"><img class="logo-mobile" src="assets/images/logo/logo.svg" alt="" /></a>

        </div>
        <div class="mt-3 ml-auto mr-2">
            <!-- <button type="button" class="btn btn-outline-primary btn-outline-primary1"
                routerLink='create-supplier-account'>Start Selling</button> -->
        </div>
        <div class="mt-3 ">
            <!-- <button type="button" class="btn btn-outline-light btn-outline-light1" routerLink='login'>
                Login
            </button> -->
        </div>
    </div>
</header>
<div class="page-content">
    <div class="container">
        <div>
            <h2 class="title text-center mb-3 mt-3">Privacy and Policy</h2>
        </div>

        <div class="pb-3">
            <p class="term-body">
                This privacy notice for Pandolab Pte. Ltd. ("Company," "we," "us," or
                "our"), describes how and why we might collect, store, use, and/or
                share ("process") your information when you use our services
                ("Services''), such as when you:
            </p>

            <ul class="ul_text">
                <li>
                    Visit our website at
                    <span style="color: #4B93ED"><a href="https://Hatriks.com/">https://Hatriks.com/</a>
                    </span>, or any website of ours that links to this privacy notice
                </li>
                <li>
                    Engage with us in other related ways, including any sales,
                    marketing, or events
                </li>
            </ul>

            <p class="term-body">
                <strong>Questions or concerns?</strong><br>
                Reading this privacy notice will help you understand your privacy
                rights and choices. If you do not agree with our policies and
                practices, please do not use our Services. If you still have any
                questions or concerns, please contact us at
                <span style="color: #4B93ED"><a href="mailto:admin@Hatriks.com">admin@Hatriks.com</a>
                </span>
                .
            </p>
        </div>

        <div class="pb-3">
            <h2 class="title">SUMMARY OF KEY POINTS</h2>

            <p class="term-body">
                <em>This summary provides key points from our privacy notice, but you
                    can find out more details about any of these topics by clicking the
                    link following each key point or by using our table of contents
                    below to find the section you are looking for.</em>
            </p>
        </div>





        <div class="pb-3">
            <h3 class="title  mb-2">
                1. What information do we collect?
            </h3>

            <p class="term-body"><strong>Personal information you disclose to us</strong></p>
            <p class="term-body"> <strong>In Short:</strong> We collect personal information that you provide to us.
            </p>
            <p class="term-body">
                We collect personal information that you voluntarily provide to us when you register on the
                Services,
                express an interest in obtaining information about us or our products and Services, when you
                participate
                in activities on the Services, or otherwise when you contact us.

                <br>
                <strong>Personal Information Provided by You.</strong> The personal information that we collect
                depends
                on the context of your interactions with us and the Services, the choices you make, and the products
                and
                features you use. The personal information we collect may include the following:

                <br>

                • names <br>
                • email addresses <br>
                • mailing addresses <br>
                • usernames <br>
                • passwords <br>
                • billing addresses <br>
                • debit/credit card numbers <br>
            </p>

            <br>





            <!-- <ul>
          <li>
            • names
           </li>
           <li>
            • email addresses
            
           </li>
           <li>• mailing addresses
            
            
            
            </li>
           <li>• usernames</li>
           <li>• passwords</li>
           <li>• billing addresses</li>
           <li>• debit/credit card numbers</li>
        </ul> -->


            <p class="term-body">
                <strong>Sensitive Information.</strong> We do not process sensitive information. <br><br>

                <strong>Payment Data.</strong> We may collect data necessary to process your payment if you make
                purchases, such as your payment instrument number (such as a credit card number), and the security
                code
                associated with your payment instrument. All payment data is stored by __________. You may find
                their
                privacy notice link(s) here: __________.

                <br><br>

                All personal information that you provide to us must be true, complete, and accurate, and you must
                notify us of any changes to such personal information.

                <br><br>

                <strong>Information automatically collected</strong>

                <br><br>

                <strong>In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or
                browser
                and device characteristics — is collected automatically when you visit our Services.

                <br><br>

                We automatically collect certain information when you visit, use, or navigate the Services. This
                information does not reveal your specific identity (like your name or contact information) but may
                include device and usage information, such as your IP address, browser and device characteristics,
                operating system, language preferences, referring URLs, device name, country, location, information
                about how and when you use our Services, and other technical information. This information is
                primarily
                needed to maintain the security and operation of our Services, and for our internal analytics and
                reporting purposes.

                <br><br>

                Like many businesses, we also collect information through cookies and similar technologies.

                <br><br>

                The information we collect includes:

                <br><br>

                <strong>• Log and Usage Data.</strong> Log and usage data is service-related, diagnostic, usage, and
                performance information our servers automatically collect when you access or use our Services and
                which
                we record in log files. Depending on how you interact with us, this log data may include your IP
                address, device information, browser type, and settings and information about your activity in the
                Services (such as the date/time stamps associated with your usage, pages and files viewed, searches,
                and
                other actions you take such as which features you use), device event information (such as system
                activity, error reports (sometimes called "crash dumps"), and hardware settings).
                <br><br>
                <strong>• Device Data.</strong> We collect device data such as information about your computer,
                phone,
                tablet, or other device you use to access the Services. Depending on the device used, this device
                data
                may include information such as your IP address (or proxy server), device and application
                identification
                numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier,
                operating system, and system configuration information.
                <br><br>
                <strong>• Location Data.</strong> We collect location data such as information about your device's
                location, which can be either precise or imprecise. How much information we collect depends on the
                type
                and settings of the device you use to access the Services. For example, we may use GPS and other
                technologies to collect geolocation data that tells us your current location (based on your IP
                address).
                You can opt out of allowing us to collect this information either by refusing access to the
                information
                or by disabling your Location setting on your device. However, if you choose to opt out, you may not
                be
                able to use certain aspects of the Services.
            </p>
        </div>

        <div class="pb-3">
            <h3 class="title  mb-2">
                2. How do we process your information?
            </h3>

            <p class="term-body">
                <strong>In Short:</strong> We process your information to provide, improve, and administer our
                Services,
                communicate with you, for security and fraud prevention, and to comply with law. We may also process
                your information for other purposes with your consent.

                <br><br>

                <strong>We process your personal information for a variety of reasons, depending on how you interact
                    with our Services, including:</strong>

                <br><br>

                <strong>• To facilitate account creation and authentication and otherwise manage user
                    accounts.</strong><br>
                We may process your information so you can create and log in to your account, as well as keep your
                account in working order.

                <br> <br>

                <strong>• To deliver and facilitate delivery of services to the user.</strong><br>

                We may process your information to provide you with the requested service.

                <br><br>

                <strong>• To respond to user inquiries/offer support to users.</strong><br> We may process your
                information to respond to your inquiries and solve any potential issues you might have with the
                requested service.
                <br><br>

                <strong>• To fulfil and manage your orders.</strong><br> We may process your information to fulfil
                and
                manage your orders, payments, returns, and exchanges made through the Services.
                <br><br>
                <strong>• To save or protect an individual's vital interest.</strong><br> We may process your
                information when necessary to save or protect an individual’s vital interest, such as to prevent
                harm.
            </p>
        </div>

        <div class="pb-3">
            <h3 class="title  mb-2">
                3. What legal bases do we rely on to process your information?
            </h3>

            <p class="term-body">
                <strong>In Short:</strong> We only process your personal information when we believe it is necessary
                and
                we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your
                consent,
                to comply with laws, to provide you with services to enter into or fulfil our contractual
                obligations,
                to protect your rights, or to fulfil our legitimate business interests.
                <br><br>

                <strong>If you are located in the EU or UK, this section applies to you.</strong>

                <br><br>

                The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal
                bases we
                rely on in order to process your personal information. As such, we may rely on the following legal
                bases
                to process your personal information:

                <br><br>

                <strong>• Consent.</strong><br> We may process your information if you have given us permission
                (i.e.,
                consent) to use your personal information for a specific purpose. You can withdraw your consent at
                any
                time.
                <br><br>

                <strong>• Performance of a Contract.</strong><br> We may process your personal information when we
                believe it is necessary to fulfil our contractual obligations to you, including providing our
                Services
                or at your request prior to entering into a contract with you.

                <br><br>

                <strong>• Legal Obligations.</strong> <br> We may process your information where we believe it is
                necessary for compliance with our legal obligations, such as to cooperate with a law enforcement
                body or
                regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in
                litigation in which we are involved.
                <br><br>
                <strong>• Vital Interests.</strong><br> We may process your information where we believe it is
                necessary
                to protect your vital interests or the vital interests of a third party, such as situations
                involving
                potential threats to the safety of any person.

                <br><br>

                If you are located in Canada, this section applies to you.

                <br><br>

                We may process your information if you have given us specific permission (i.e., express consent) to
                use
                your personal information for a specific purpose, or in situations where your permission can be
                inferred
                (i.e., implied consent). You can withdraw your consent at any time.

                <br><br>

                In some exceptional cases, we may be legally permitted under applicable law to process your
                information
                without your consent, including, for example:
                <br><br>
                • If collection is clearly in the interests of an individual and consent cannot be obtained in a
                timely
                way
                <br><br>
                • For investigations and fraud detection and prevention
                <br><br>
                • For business transactions provided certain conditions are met
                <br><br>
                • If it is contained in a witness statement and the collection is necessary to assess, process, or
                settle an insurance claim
                <br><br>
                • For identifying injured, ill, or deceased persons and communicating with next of kin
                <br><br>
                • If we have reasonable grounds to believe an individual has been, is, or may be victim of financial
                abuse
                <br><br>
                • If it is reasonable to expect collection and use with consent would compromise the availability or
                the
                accuracy of the information and the collection is reasonable for purposes related to investigating a
                breach of an agreement or a contravention of the laws of Canada or a province
                <br><br>
                • If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court
                relating to the production of records
                <br><br>
                • If it was produced by an individual in the course of their employment, business, or profession and
                the
                collection is consistent with the purposes for which the information was produced
                <br><br>
                • If the collection is solely for journalistic, artistic, or literary purposes
                <br><br>
                • If the information is publicly available and is specified by the regulations
            </p>
        </div>


        <div class="pb-3">
            <h3 class="title  mb-2">
                4. When and with whom do we share your personal information?
            </h3>

            <p class="term-body">

                <strong>In Short:</strong> We may share information in specific situations described in this section
                and/or with the following third parties.

                <br><br>

                We may need to share your personal information in the following situations:

                <br><br>

                <strong>• Business Transfers.</strong> We may share or transfer your information in connection with,
                or
                during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                portion of our business to another company.

            </p>

        </div>

        <div class="pb-3">
            <h3 class="title  mb-2">
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h3>

            <p class="term-body">

                <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store
                your
                information.

                <br><br>

                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
                store
                information. Specific information about how we use such technologies and how you can refuse certain
                cookies is set out in our Cookie Notice.

            </p>

        </div>

        <div class="pb-3">
            <h3 class="title  mb-2">
                6. How long do we keep your information?
            </h3>

            <p class="term-body">

                <strong>In Short:</strong> We keep your information for as long as necessary to fulfil the purposes
                outlined in this privacy notice unless otherwise required by law.

                <br><br>

                We will only keep your personal information for as long as it is necessary for the purposes set out
                in
                this privacy notice, unless a longer retention period is required or permitted by law (such as tax,
                accounting, or other legal requirements). No purpose in this notice will require us to keep your
                personal information for longer than the period of time in which users have an account with us.

                <br><br>

                When we have no ongoing legitimate business need to process your personal information, we will
                either
                delete or anonymize such information, or, if this is not possible (for example, because your
                personal
                information has been stored in backup archives), then we will securely store your personal
                information
                and isolate it from any further processing until deletion is possible.

            </p>

        </div>

        <div class="pb-3">
            <h3 class="title  mb-2">
                7. How do we keep your information safe?
            </h3>

            <p class="term-body">

                <strong>In Short:</strong> We aim to protect your personal information through a system of
                organisational and technical security measures.
                <br><br>

                We have implemented appropriate and reasonable technical and organisational security measures
                designed
                to protect the security of any personal information we process. However, despite our safeguards and
                efforts to secure your information, no electronic transmission over the Internet or information
                storage
                technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
                cybercriminals, or other unauthorised third parties will not be able to defeat our security and
                improperly collect, access, steal, or modify your information. Although we will do our best to
                protect
                your personal information, transmission of personal information to and from our Services is at your
                own
                risk. You should only access the Services within a secure environment.

            </p>

        </div>

        <div class="pb-3">
            <h3 class="title  mb-2">
                8. What are your privacy rights?
            </h3>

            <p class="term-body">

                <strong>In Short:</strong> In some regions, such as the European Economic Area (EEA), United Kingdom
                (UK), and Canada, you have rights that allow you greater access to and control over your personal
                information. You may review, change, or terminate your account at any time.

                <br><br>

                In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data
                protection
                laws. These may include the right (i) to request access and obtain a copy of your personal
                information,
                (ii) to request rectification or erasure; (iii) to restrict the processing of your personal
                information;
                and (iv) if applicable, to data portability. In certain circumstances, you may also have the right
                to
                object to the processing of your personal information. You can make such a request by contacting us
                by
                using the contact details provided in the section “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?” below.

                <br><br>

                We will consider and act upon any request in accordance with applicable data protection laws.

                <br><br>

                If you are located in the EEA or UK and you believe we are unlawfully processing your personal
                information, you also have the right to complain to your local data protection supervisory
                authority.
                You can find their contact details here: <span style="color: #4B93ED ;"><a
                        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                        target="_blank">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a></span>.

                <br><br>

                If you are located in Switzerland, the contact details for the data protection authorities are
                available
                here: <span style="color: #4B93ED ;"><a target="_blank"
                        href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a></span>.

                <br><br>

                <strong>Withdrawing your consent:</strong> If we are relying on your consent to process your
                personal
                information, which may be express and/or implied consent depending on the applicable law, you have
                the
                right to withdraw your consent at any time. You can withdraw your consent at any time by contacting
                us
                by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                below
                or updating your preferences.

                <br><br>

                However, please note that this will not affect the lawfulness of the processing before its
                withdrawal,
                nor when applicable law allows, will it affect the processing of your personal information conducted
                in
                reliance on lawful processing grounds other than consent.

                <br><br>

                <strong>Opting out of marketing and promotional communications:</strong> You can unsubscribe from
                our
                marketing and promotional communications at any time by clicking on the unsubscribe link in the
                emails
                that we send, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US
                ABOUT THIS NOTICE?" below. You will then be removed from the marketing lists. However, we may still
                communicate with you — for example, to send you service-related messages that are necessary for the
                administration and use of your account, to respond to service requests, or for other non-marketing
                purposes.

                <br><br>

                <strong>Account Information</strong>

                <br><br>

                If you would at any time like to review or change the information in your account or terminate your
                account, you can:
                <br><br>
                • Log in to your account settings and update your user account.
                <br><br>
                Upon your request to terminate your account, we will deactivate or delete your account and
                information
                from our active databases. However, we may retain some information in our files to prevent fraud,
                troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with
                applicable legal requirements.
                <br><br>
                <strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by
                default. If you prefer, you can usually choose to set your browser to remove cookies and to reject
                cookies. If you choose to remove cookies or reject cookies, this could affect certain features or
                services of our Services. To opt out of interest-based advertising by advertisers on our Services
                visit
                <span style="color: #4B93ED ;"><a target="_blank"
                        href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a></span>.
                <br><br>
                If you have questions or comments about your privacy rights, you may email us at <span
                    style="color: #4B93ED ;"><a href="mailto:admin@Hatriks.com">admin@Hatriks.com</a></span>
                .

            </p>

        </div>

        <div class="pb-3">
            <h3 class="title  mb-2">
                9. Controls for do-not-track features?
            </h3>

            <p class="term-body">

                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                ("DNT") feature or setting you can activate to signal your privacy preference not to have data about
                your online browsing activities monitored and collected. At this stage no uniform technology
                standard
                for recognizing and implementing DNT signals has been finalised. As such, we do not currently
                respond to
                DNT browser signals or any other mechanism that automatically communicates your choice not to be
                tracked
                online. If a standard for online tracking is adopted that we must follow in the future, we will
                inform
                you about that practice in a revised version of this privacy notice.

            </p>

        </div>

        <div class="pb-3">
            <h3 class="title  mb-2">
                10. Do California residents have specific privacy rights?
            </h3>

            <p class="term-body">

                <strong>In Short:</strong> Yes, if you are a resident of California, you are granted specific rights
                regarding access to your personal information.

                <br><br>

                California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users
                who
                are California residents to request and obtain from us, once a year and free of charge, information
                about categories of personal information (if any) we disclosed to third parties for direct marketing
                purposes and the names and addresses of all third parties with which we shared personal information
                in
                the immediately preceding calendar year. If you are a California resident and would like to make
                such a
                request, please submit your request in writing to us using the contact information provided below.

                <br><br>

                If you are under 18 years of age, reside in California, and have a registered account with Services,
                you
                have the right to request removal of unwanted data that you publicly post on the Services. To
                request
                removal of such data, please contact us using the contact information provided below and include the
                email address associated with your account and a statement that you reside in California. We will
                make
                sure the data is not publicly displayed on the Services, but please be aware that the data may not
                be
                completely or comprehensively removed from all our systems (e.g., backups, etc.).

            </p>

        </div>

        <div class="pb-3">
            <h3 class="title  mb-2">
                11. Do we make updates to this notice?
            </h3>

            <p class="term-body">

                <strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with
                relevant
                laws.

                <br><br>

                We may update this privacy notice from time to time. The updated version will be indicated by an
                updated
                "Revised" date and the updated version will be effective as soon as it is accessible. If we make
                material changes to this privacy notice, we may notify you either by prominently posting a notice of
                such changes or by directly sending you a notification. We encourage you to review this privacy
                notice
                frequently to be informed of how we are protecting your information.

            </p>

        </div>

        <div class="pb-3">
            <h3 class="title  mb-2">
                12. How can you contact us about this notice?
            </h3>

            <p class="term-body">

                If you have questions or comments about this notice, you may email us at <span style="color: #4B93ED ;">
                    <a href="mailto:admin@Hatriks.com">admin@Hatriks.com</a></span> or by post to:
                <br>

                Hatrik <br>
                68 Circular Road, #02-01 Singapore <br>
                Singapore, Singapore 049422 <br>
                Singapore

            </p>

        </div>

        <div class="pb-3">
            <h3 class="title  mb-2">
                13. How can you review, update, or delete the data we collect from you?
            </h3>

            <p class="term-body">

                Based on the applicable laws of your country, you may have the right to request access to the
                personal
                information we collect from you, change that information, or delete it in some circumstances. To
                request
                to review, update, or delete your personal information, please submit a request form by clicking
                here.

            </p>

        </div>












    </div>
</div>

<div class="cta cta-display bg-image pt-4 pb-4" style="background-image: url(assets/images/backgrounds/cta/bg-7.jpg)">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-9 col-xl-7">
                <div class="row no-gutters flex-sm-row align-items-sm-center">
                    <div class="col-12 col-sm">
                        <h3 class="cta-title text-white">If You Have More Questions</h3>
                        <p class="cta-desc text-white">Quisque volutpat mattis eros</p>
                    </div>

                    <!-- <div class="col-auto">
                        <a routerLink="/pages/contact" class="btn btn-outline-white"><span>CONTACT US</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <footer>
    <div class="footer-wrap">
        <div class="second_class">
            <div class="container second_class_bdr">
                <div class="row px-7">
                    <div class="col-md-4 col-sm-6">
                        <div class="footer-logo"> <img src="../../../assets/images/Pando_logo1.png" alt=""
                                style="width: 200px;">
                        </div>
                        <p>Your one-stop career platform to find Jobs, Internships, Professional Trainings,
                            Projects, and Volunteering Opportunities.</p>
                    </div>
                    <div class="col-md-2 col-sm-6">
                        <h3>Useful Links</h3>
                        <ul class="footer-links">
                            <li><a href="#">About Hatrik</a>
                            </li>
                            <li><a href="#">Our Services</a>
                            </li>
                         
                            <li><a href="#" target="_blank">FAQ</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-3 col-sm-6">
                        <h3>Customer Service</h3>
                        <ul class="footer-category">
                            <li><a href="#">Returns</a>
                            </li>
                            <li><a routerLink="/terms and conditions">Terms and conditions
                                </a>
                            </li>
                            <li><a routerLink="/privacy and policy">Privacy and Policy</a>
                            </li>
                        </ul>
                        <div class="clearfix"></div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                        <h3>My Account</h3>
                        <ul class="footer-links">
                           
                            <li><a href="#">Help and Support</a>
                            </li>
                            <li><a routerLink="/pages/contact">Contact us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="container-fluid">
                <div class="copyright"> Copyright © 2022 Hatrik. All Rights Reserved.</div>
            </div>

        </div>
    </div>
</footer> -->