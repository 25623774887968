<div style="background: #ffffff">
  <div class="header d-flex justify-content-between w-100">
    <div class="mt-3">
      <a href="#"
        ><img src="assets/images/Hatrik-logo.png" width="97" height="45" alt=""
      /></a>
    </div>
    <div class="mt-3">
      <div>
        <button
          type="button"
          class="btn btn-outline-light btn-outline-light1"
          routerLink="/login"
          *ngIf="!checkToken"
        >
          Login
        </button>
        <button
          type="button"
          class="btn btn-outline-light btn-outline-light1"
          *ngIf="checkToken"
          (click)="logout()"
        >
          Logout
        </button>
      </div>
    </div>
  </div>

  <div class="row sign-wrapper" *ngIf="stepOneForm">
    <div class="login-div col-lg-6">
      <div class="login-card">
        <p class="login">Welcome to HATRIK Marketplace</p>
        <p class="please-your">Create your account to start selling</p>
        <form [formGroup]="signupForm" (ngSubmit)="signUp()">
          <div *ngIf="!otpVerification">
            <div class="row">
              <div class="col-md mr-1">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                    placeholder="First Name"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                    (keydown)="spaceRemoveFirst($event)"
                    maxlength="25"
                  />
                  <div
                    *ngIf="
                      (submitted && f.name.errors) ||
                      (signupForm.controls['name'].touched &&
                        !signupForm.controls['name'].valid)
                    "
                  >
                    <small class="text-danger">
                      <span
                        *ngIf="signupForm.controls['name'].hasError('required')"
                      >
                        First Name is Required
                      </span>
                      <span
                        *ngIf="
                          signupForm.controls['name'].hasError('maxlength')
                        "
                      >
                        Max Length is 25
                      </span>
                      <span
                        *ngIf="
                          signupForm.controls['name'].hasError('minlength')
                        "
                      >
                        Min Length is 2
                      </span>
                      <span
                        *ngIf="
                          signupForm.controls['name'].hasError('pattern') &&
                          !signupForm.controls['name'].hasError('minlength')
                        "
                      >
                        Numeric, Special characters and Multiple Space are not
                        allowed
                      </span>
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-md ml-1">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="last_name"
                    placeholder="Last Name"
                    [ngClass]="{
                      'is-invalid': submitted && f.last_name.errors
                    }"
                    (keydown)="spaceRemoveFirst($event)"
                    maxlength="25"
                  />
                  <div
                    *ngIf="
                      (submitted && f.last_name.errors) ||
                      (signupForm.controls['last_name'].touched &&
                        !signupForm.controls['last_name'].valid)
                    "
                  >
                    <small class="text-danger">
                      <span
                        *ngIf="
                          signupForm.controls['last_name'].hasError('required')
                        "
                      >
                        Last Name is Required
                      </span>
                      <span
                        *ngIf="
                          signupForm.controls['last_name'].hasError('maxlength')
                        "
                      >
                        Max Length is 25
                      </span>
                      <span
                        *ngIf="
                          signupForm.controls['last_name'].hasError('minlength')
                        "
                      >
                        Min Length is 2
                      </span>
                      <span
                        *ngIf="
                          signupForm.controls['last_name'].hasError(
                            'pattern'
                          ) &&
                          !signupForm.controls['last_name'].hasError(
                            'minlength'
                          )
                        "
                      >
                        Numeric, Special characters and Multiple Space are not
                        allowed
                      </span>
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group mt-3">
              <input
                type="email"
                class="form-control"
                placeholder="Email Address"
                formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              />

              <div
                *ngIf="
                  (submitted && f.email.errors) ||
                  (signupForm.controls['email'].touched &&
                    !signupForm.controls['email'].valid)
                "
              >
                <small class="text-danger">
                  <span
                    *ngIf="signupForm.controls['email'].hasError('required')"
                  >
                    Email is Required
                  </span>
                  <span
                    *ngIf="signupForm.controls['email'].hasError('pattern')"
                  >
                    Enter Your Valid Email
                  </span>
                  <span
                    *ngIf="signupForm.controls['email'].hasError('maxlength')"
                  >
                    Email accepts less then 30 charactor
                  </span>
                </small>
              </div>
            </div>

            <div class="form-group f2">
              <ngx-intl-tel-input
                (input)="onInputChange($event)"
                [cssClass]="'custom'"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
                ]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.UnitedStates"
                [maxLength]="15"
                [phoneValidation]="true"
                [separateDialCode]="separateDialCode"
                name="phone"
                formControlName="phone"
                (countryChange)="countryChange($event)"
                appPhoneMask
                [countryCode]="selectedCountryCode"
              >
              </ngx-intl-tel-input>

              <div
                *ngIf="
                  (submitted && f.phone.errors) ||
                  (signupForm.controls['phone'].touched &&
                    !signupForm.controls['phone'].valid)
                "
              >
                <small class="text-danger">
                  <span
                    *ngIf="signupForm.controls['phone'].hasError('required')"
                  >
                    Phone Number Is Required
                  </span>

                  <span
                    *ngIf="
                      !signupForm.controls['phone'].invalid == false &&
                      !signupForm.controls['phone'].hasError('required')
                    "
                  >
                    Please Enter Valid 10 Digit Number
                  </span>
                </small>
              </div>
            </div>

            <!-- <div class="form-group mt-3">
              <input
                type="text"
                class="form-control"
                placeholder="Phone Number"
                formControlName="phone"
                [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
              />

              <div
                *ngIf="
                  (submitted && f.phone.errors) ||
                  (signupForm.controls['phone'].touched &&
                    !signupForm.controls['phone'].valid)
                "
              >
                <small class="text-danger">
                  <span
                    *ngIf="signupForm.controls['phone'].hasError('required')"
                  >
                    Phone Number is Required
                  </span>
                  <span
                    *ngIf="signupForm.controls['phone'].hasError('pattern')"
                  >
                    Enter Your Valid Phone Number
                  </span>
                </small>
              </div>
            </div> -->

            <div class="form-group mt-3 icon-div">
              <input
                type="password"
                class="form-control"
                placeholder="Password"
                formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                id="c_password"
                #password2
                [(ngModel)]="c_password"
                autocomplete="off"
              />
              <div (click)="changeType(password2, 2)" class="Show">
                <span
                  ><i
                    [ngClass]="toggle2 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    class="eyeIcon"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="
                (submitted && f.password.errors) ||
                (signupForm.controls['password'].touched &&
                  !signupForm.controls['password'].valid)
              "
            >
              <small class="text-danger">
                <span
                  *ngIf="signupForm.controls['password'].hasError('required')"
                >
                  Password is Required
                </span>
                <span
                  *ngIf="signupForm.controls['password'].hasError('pattern')"
                >
                  Password should be at least 8 characters and contain a
                  combination of lettters, numbers and special characters
                </span>
              </small>
            </div>
            <div class="form-group mt-3 icon-div">
              <input
                type="password"
                class="form-control"
                placeholder=" Confirm Password"
                formControlName="confirm_password"
                [ngClass]="{
                  'is-invalid': submitted && f.confirm_password.errors
                }"
                [(ngModel)]="a_password"
                id="a_password"
                #password1
              />
              <div (click)="changeType(password1, 1)" class="Show">
                <span
                  ><i
                    [ngClass]="toggle1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    class="eyeIcon"
                  ></i
                ></span>
              </div>
            </div>

            <div
              *ngIf="
                (submitted && f.confirm_password.errors) ||
                (signupForm.controls['confirm_password'].touched &&
                  !signupForm.controls['confirm_password'].valid)
              "
            >
              <small class="text-danger">
                <div
                  *ngIf="
                    (submitted && f.confirm_password.errors) ||
                    (signupForm.controls['confirm_password'].touched &&
                      !signupForm.controls['confirm_password'].valid)
                  "
                >
                  <span
                    *ngIf="
                      signupForm.controls['confirm_password'].hasError(
                        'required'
                      )
                    "
                  >
                    Confirm Password is Required
                  </span>
                  <span
                    *ngIf="signupForm.hasError('mustMatch', 'confirm_password')"
                  >
                    Password And Confirm Password Not Match
                  </span>
                </div>
              </small>
            </div>
          </div>
          <div *ngIf="otpVerification">
            <p>Please enter the otp you have received on your mail</p>
            <div
              class="d-flex justify-content-between"
              style="margin-bottom: 70px"
            >
              <ngx-otp-input
                style="margin-left: 10px"
                [config]="otpInputConfig"
                (otpChange)="handeOtpChange($event)"
                (fill)="handleFillEvent($event)"
                class="ngx-otp"
              >
              </ngx-otp-input>
            </div>
          </div>
          <div class="form-footer d-flex">
            <button
              *ngIf="step != 1"
              (click)="previous()"
              class="btn btn-primary form-control button3"
            >
              Previous
            </button>
            <button
              *ngIf="step != 2"
              [disabled]="!signupForm.valid"
              class="form-control btn btn-primary button4"
            >
              <ng-container *ngIf="!otpVerification">
                Create Account</ng-container
              >
              <ng-container *ngIf="otpVerification"> Verify</ng-container>
            </button>
            <button
              *ngIf="step == 2"
              class="btn btn-primary form-control ml-auto button3"
            >
              SIGN UP
            </button>
          </div>
          <div *ngIf="step != 2">
            <h5 class="privacy">
              By clicking you agree to our
              <a href="Terms & Conditions">Terms & Conditions</a> and
              <a href="Privacy Policy">Privacy Policy</a>
            </h5>
          </div>
        </form>
      </div>
    </div>
    <div class="login-div-left col-lg-6">
      <h4>
        Complete the easy setup to start selling your products on the HATRIK
        Marketplace
      </h4>
      <div class="d-flex justify-content-center">
        <img src="assets/images/Img.png" class="images" />
      </div>
      <div>
        <h6 class="text">All you need to sell on HATRIK Marketplace is</h6>
      </div>
      <div class="footer-icon">
        <img src="assets/images/hatrik.svg" />
      </div>
    </div>
  </div>

  <div *ngIf="stepTwoForm">
    <div class="container-fluid supplierStiper">
      <div class="row">
        <div class="col-md">
          <div class="stiper-page mt-5 mx-md-5 px-4 pt-3">
            <h6 class="striper-heading">
              Please fill all the steps below to start selling
            </h6>
            <form [formGroup]="supplierForm">
              <app-progress
                #progress
                (stateChange)="onStateChange($event)"
                [selectedIndex]="activeIndex"
              >
                <app-progress-step>
                  <div class="Details1">
                    <label class="labled">Business Information Details</label>
                    <div class="form-group f2">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Company Name"
                        formControlName="business_name"
                        [ngClass]="{
                          'is-invalid': submitteds3 && fs.business_name.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="100"
                      />
                      <div
                        *ngIf="
                          (submitteds3 && fs.business_name.errors) ||
                          (supplierForm.controls['business_name'].touched &&
                            !supplierForm.controls['business_name'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls['business_name'].hasError(
                                'required'
                              )
                            "
                          >
                            Company Name is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['business_name'].hasError(
                                'minlength'
                              )
                            "
                          >
                            Min Length is 2
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['business_name'].hasError(
                                'maxlength'
                              )
                            "
                          >
                            Max Length is 100
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['business_name'].hasError(
                                'pattern'
                              )
                            "
                          >
                            Only Characters are Allowed
                          </span>
                        </small>
                      </div>
                    </div>

                    <div class="form-group f2">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Owner's Name"
                        formControlName="owner_name"
                        [ngClass]="{
                          'is-invalid': submitteds3 && fs.owner_name.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="50"
                      />
                      <div
                        *ngIf="
                          (submitteds3 && fs.owner_name.errors) ||
                          (supplierForm.controls['owner_name'].touched &&
                            !supplierForm.controls['owner_name'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls['owner_name'].hasError(
                                'required'
                              )
                            "
                          >
                            Owner Name Is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['owner_name'].hasError(
                                'minlength'
                              )
                            "
                          >
                            Min Length Is 2
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['owner_name'].hasError(
                                'maxlength'
                              )
                            "
                          >
                            Max Length Is 50
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['owner_name'].hasError(
                                'pattern'
                              )
                            "
                          >
                            Only Characters are Allowed
                          </span>
                        </small>
                      </div>
                    </div>

                    <div class="form-group f2">
                      <input
                        (input)="FormateInput(inputField)"
                        #inputField
                        type="text"
                        class="form-control"
                        placeholder="Employer Identification Number"
                        formControlName="ein_number"
                        (keydown)="spaceRemoveFirst($event)"
                        [ngClass]="{
                          'is-invalid': submitteds2 && fs.ein_number.errors
                        }"
                      />
                      <div
                        *ngIf="
                          (submitteds3 && fs.ein_number.errors) ||
                          (supplierForm.controls['ein_number'].touched &&
                            !supplierForm.controls['ein_number'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls['ein_number'].hasError(
                                'required'
                              )
                            "
                          >
                            EIN Number Is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['ein_number'].hasError(
                                'minlength'
                              )
                            "
                          >
                            Min Length is 9
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['ein_number'].hasError(
                                'maxlength'
                              )
                            "
                          >
                            Max Length is 15
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['ein_number'].hasError(
                                'pattern'
                              ) &&
                              !supplierForm.controls['ein_number'].hasError(
                                'minlength'
                              )
                            "
                          >
                            Please Enter Your Valid EIN Number
                          </span>
                        </small>
                      </div>
                    </div>

                    <div class="form-group f2">
                      <input
                        ngx-google-places-autocomplete
                        [options]="options"
                        #placesRef2="ngx-places"
                        (onAddressChange)="handleAddressChange2($event)"
                        type="text"
                        class="form-control"
                        placeholder="Address line 1"
                        formControlName="bussiness_address"
                        [ngClass]="{
                          'is-invalid':
                            submitteds3 && fs.bussiness_address.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="100"
                      />
                      <div
                        *ngIf="
                          (submitteds3 && fs.bussiness_address.errors) ||
                          (supplierForm.controls['bussiness_address'].touched &&
                            !supplierForm.controls['bussiness_address'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'bussiness_address'
                              ].hasError('required')
                            "
                          >
                            Address line 1 ( Street No. ) is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'bussiness_address'
                              ].hasError('minlength')
                            "
                          >
                            Min Length is 5
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'bussiness_address'
                              ].hasError('maxlength')
                            "
                          >
                            Max Length Is 100
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'bussiness_address'
                              ].hasError('pattern')
                            "
                          >
                            Only Characters are Allowed
                          </span>
                        </small>
                      </div>
                    </div>

                    <div class="form-group f2">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Address line 2"
                        formControlName="bussiness_address2"
                        [ngClass]="{
                          'is-invalid':
                            submitteds3 && fs.bussiness_address2.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="100"
                      />
                      <div
                        *ngIf="
                          (submitteds3 && fs.bussiness_address2.errors) ||
                          (supplierForm.controls['bussiness_address2']
                            .touched &&
                            !supplierForm.controls['bussiness_address2'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'bussiness_address2'
                              ].hasError('required')
                            "
                          >
                            Address line 2 is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'bussiness_address2'
                              ].hasError('minlength')
                            "
                          >
                            Min Length is 5
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'bussiness_address2'
                              ].hasError('maxlength')
                            "
                          >
                            Max Length Is 100
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'bussiness_address2'
                              ].hasError('pattern')
                            "
                          >
                            Only Characters are Allowed
                          </span>
                        </small>
                      </div>
                    </div>

                    <div class="form-group f2">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="City"
                        formControlName="bussiness_city"
                        [ngClass]="{
                          'is-invalid': submitteds3 && fs.bussiness_city.errors
                        }"
                        maxlength="100"
                        (keydown)="spaceRemoveFirst($event)"
                      />
                      <div
                        *ngIf="
                          (submitteds3 && fs.bussiness_city.errors) ||
                          (supplierForm.controls['bussiness_city'].touched &&
                            !supplierForm.controls['bussiness_city'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls['bussiness_city'].hasError(
                                'required'
                              )
                            "
                          >
                            City Is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['bussiness_city'].hasError(
                                'minlength'
                              )
                            "
                          >
                            Min Length Is 2
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['bussiness_city'].hasError(
                                'maxlength'
                              )
                            "
                          >
                            Max Length Is 100
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['bussiness_city'].hasError(
                                'pattern'
                              )
                            "
                          >
                            Only Characters are Allowed
                          </span>
                        </small>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="form-group f2">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="State Name"
                            formControlName="bussiness_state_id"
                            [ngClass]="{
                              'is-invalid':
                                submitteds3 && fs.bussiness_state_id.errors
                            }"
                            (keydown)="spaceRemoveFirst($event)"
                            maxlength="500"
                          />
                          <!-- <div class="col-5 mr-6"> -->
                          <!-- <select id="inputState" formControlName="bussiness_state_id" class="form-control form-select"
                            name="state" id="state" required
                            [ngClass]="{ 'is-invalid': submitteds3 && fs.bussiness_state_id.errors }">
                            <option selected [ngValue]="false">State</option>
                            <option *ngFor="let state of states" [value]="state.id">
                              {{ state.name }}
                            </option>
                          </select> -->
                          <div
                            *ngIf="
                              (submitteds3 && fs.bussiness_state_id.errors) ||
                              (supplierForm.controls['bussiness_state_id']
                                .touched &&
                                !supplierForm.controls['bussiness_state_id']
                                  .valid)
                            "
                          >
                            <small class="text-danger">
                              <span
                                *ngIf="
                                  supplierForm.controls[
                                    'bussiness_state_id'
                                  ].hasError('required')
                                "
                              >
                                State Is Required
                              </span>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col ml-2">
                        <div class="form-group f2">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Zip Code"
                            formControlName="bussiness_zip"
                            [ngClass]="{
                              'is-invalid':
                                submitteds3 && fs.bussiness_zip.errors
                            }"
                            (keydown)="spaceRemoveFirst($event)"
                          />
                          <div
                            *ngIf="
                              (submitteds3 && fs.bussiness_zip.errors) ||
                              (supplierForm.controls['bussiness_zip'].touched &&
                                !supplierForm.controls['bussiness_zip'].valid)
                            "
                          >
                            <small class="text-danger">
                              <span
                                *ngIf="
                                  supplierForm.controls[
                                    'bussiness_zip'
                                  ].hasError('required')
                                "
                              >
                                Zip Code Is Required
                              </span>
                              <span
                                *ngIf="
                                  supplierForm.controls[
                                    'bussiness_zip'
                                  ].hasError('minlength')
                                "
                              >
                                Min Length is 5
                              </span>
                              <span
                                *ngIf="
                                  supplierForm.controls[
                                    'bussiness_zip'
                                  ].hasError('pattern') &&
                                  !supplierForm.controls[
                                    'bussiness_zip'
                                  ].hasError('minlength')
                                "
                              >
                                Please Enter Valid Only Number
                              </span>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group f2">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Country Name"
                        formControlName="bussiness_country_id"
                        [ngClass]="{
                          'is-invalid':
                            submitteds3 && fs.bussiness_country_id.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="500"
                      />

                      <!-- <select id="inputState" (change)="fetchState($any($event.target).value)"
                        class="form-control form-select" formControlName="bussiness_country_id" name="country" required
                        [ngClass]="{ 'is-invalid': submitteds3 && fs.bussiness_country_id.errors }">
                        <option selected [value]="233">United States</option>
                        <option *ngFor="let country of countries" [value]="country.id">
                          {{ country.name }}
                        </option>
                      </select> -->
                      <div
                        *ngIf="
                          (submitteds3 && fs.bussiness_country_id.errors) ||
                          (supplierForm.controls['bussiness_country_id']
                            .touched &&
                            !supplierForm.controls['bussiness_country_id']
                              .valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'bussiness_country_id'
                              ].hasError('required')
                            "
                          >
                            Country Is Required
                          </span>
                        </small>
                      </div>
                    </div>

                    <div class="form-group f2">
                      <div class="stepper_button">
                        <!-- <button progressStepPrev class="form-control button3">
                          Previous
                        </button> -->
                        <button
                          [disabled]="
                            !(
                              this.supplierForm.controls.owner_name.valid &&
                              this.supplierForm.controls.business_name.valid &&
                              this.supplierForm.controls.bussiness_country_id
                                .valid &&
                              this.supplierForm.controls.bussiness_state_id
                                .valid &&
                              this.supplierForm.controls.bussiness_city.valid &&
                              this.supplierForm.controls.bussiness_zip.valid &&
                              this.supplierForm.controls.bussiness_address
                                .valid &&
                              this.supplierForm.controls.ein_number.valid
                            )
                          "
                          class="form-control button2"
                          (click)="goNext(progress, 'business_info')"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </app-progress-step>

                <app-progress-step>
                  <div class="Details1">
                    <label class="labled">Supplier Details</label>
                    <div class="form-group f2">
                      <input
                        type="checkbox"
                        id="business_owner"
                        (change)="handleChange($event)"
                      />
                      <label
                        for="business_owner"
                        style="color: #777; margin-left: 1rem"
                        >Same as Business Address
                      </label>
                      <!-- <div
                        *ngIf="(submitteds3 && fs.is_owner.errors) ||supplierForm.controls['is_owner'].touched && !supplierForm.controls['is_owner'].valid">
                        <small class="text-danger">
                          <span *ngIf="supplierForm.controls['is_owner'].hasError('required')">
                            is Owner Is Required
                          </span>
                        </small>
                      </div> -->
                    </div>

                    <!-- <div class="form-group f2">
                      <input type="checkbox" id="business_owner" formControlName="is_owner">
                      <label for="business_owner" style="color: #777; margin-left: 1rem;">is Owner</label>
                      <div
                        *ngIf="(submitteds3 && fs.is_owner.errors) ||supplierForm.controls['is_owner'].touched && !supplierForm.controls['is_owner'].valid">
                        <small class="text-danger">
                          <span *ngIf="supplierForm.controls['is_owner'].hasError('required')">
                            is Owner Is Required
                          </span>
                        </small>
                      </div>
                    </div> -->

                    <!-- <div class=" form-group f2">
                      <input type="text" class="form-control" placeholder="Address" formControlName="supplier_address"
                        [ngClass]="{ 'is-invalid': submitteds3 && fs.supplier_address.errors }"
                        (keydown)="spaceRemoveFirst($event)">
                      <div
                        *ngIf="(submitteds3 && fs.supplier_address.errors) ||supplierForm.controls['supplier_address'].touched && !supplierForm.controls['supplier_address'].valid">
                        <small class="text-danger">
                          <span *ngIf="supplierForm.controls['supplier_address'].hasError('required')">
                            Address Is Required
                          </span>
                          <span *ngIf="supplierForm.controls['supplier_address'].hasError('minlength')">
                            Min Length is 5
                          </span>
                          <span *ngIf="supplierForm.controls['supplier_address'].hasError('maxlength')">
                            Max Length Is 100
                          </span>
                          <span *ngIf="supplierForm.controls['supplier_address'].hasError('pattern')">
                            Only Characters are Allowed
                          </span>
                        </small>
                      </div>
                    </div> -->

                    <div class="form-group f2">
                      <input
                        ngx-google-places-autocomplete
                        [options]="options"
                        #placesRef="ngx-places"
                        (onAddressChange)="handleAddressChange($event)"
                        type="text"
                        class="form-control"
                        placeholder="Address line 1"
                        formControlName="supplier_address"
                        [ngClass]="{
                          'is-invalid':
                            submitteds3 && fs.supplier_address.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="500"
                      />
                      <div
                        *ngIf="
                          (submitteds3 && fs.supplier_address.errors) ||
                          (supplierForm.controls['supplier_address'].touched &&
                            !supplierForm.controls['supplier_address'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'supplier_address'
                              ].hasError('required')
                            "
                          >
                            Address line 1 ( Street No. ) is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'supplier_address'
                              ].hasError('minlength')
                            "
                          >
                            Min Length is 5
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'supplier_address'
                              ].hasError('maxlength')
                            "
                          >
                            Max Length Is 500
                          </span>
                        </small>
                      </div>
                    </div>
                    <div class="form-group f2">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Address line 2"
                        formControlName="supplier_address2"
                        [ngClass]="{
                          'is-invalid':
                            submitteds3 && fs.supplier_address2.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="500"
                      />
                      <div
                        *ngIf="
                          (submitteds3 && fs.supplier_address2.errors) ||
                          (supplierForm.controls['supplier_address2'].touched &&
                            !supplierForm.controls['supplier_address2'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'supplier_address2'
                              ].hasError('required')
                            "
                          >
                            Address line 2 is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'supplier_address2'
                              ].hasError('minlength')
                            "
                          >
                            Min Length is 5
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'supplier_address2'
                              ].hasError('maxlength')
                            "
                          >
                            Max Length Is 500
                          </span>
                        </small>
                      </div>
                    </div>

                    <div class="form-group f2">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="City"
                        formControlName="supplier_city"
                        [ngClass]="{
                          'is-invalid': submitteds3 && fs.supplier_city.errors
                        }"
                        maxlength="50"
                      />
                      <div
                        *ngIf="
                          (submitteds3 && fs.supplier_city.errors) ||
                          (supplierForm.controls['supplier_city'].touched &&
                            !supplierForm.controls['supplier_city'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls['supplier_city'].hasError(
                                'required'
                              )
                            "
                          >
                            City Is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['supplier_city'].hasError(
                                'minlength'
                              )
                            "
                          >
                            Min Length Is 5
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['supplier_city'].hasError(
                                'maxlength'
                              )
                            "
                          >
                            Max Length Is 100
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['supplier_city'].hasError(
                                'pattern'
                              )
                            "
                          >
                            Only Characters are Allowed
                          </span>
                        </small>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="form-group f2">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="State Name"
                            formControlName="supplier_state_id"
                            [ngClass]="{
                              'is-invalid':
                                submitteds3 && fs.supplier_state_id.errors
                            }"
                            (keydown)="spaceRemoveFirst($event)"
                            maxlength="500"
                          />
                          <div
                            *ngIf="
                              (submitteds3 && fs.supplier_state_id.errors) ||
                              (supplierForm.controls['supplier_state_id']
                                .touched &&
                                !supplierForm.controls['supplier_state_id']
                                  .valid)
                            "
                          >
                            <small class="text-danger">
                              <span
                                *ngIf="
                                  supplierForm.controls[
                                    'supplier_state_id'
                                  ].hasError('required')
                                "
                              >
                                State Is Required
                              </span>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col ml-2">
                        <div class="form-group f2">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Zip Code"
                            formControlName="supplier_zip"
                            [ngClass]="{
                              'is-invalid':
                                submitteds3 && fs.supplier_zip.errors
                            }"
                            (keydown)="spaceRemoveFirst($event)"
                          />
                          <div
                            *ngIf="
                              (submitteds3 && fs.supplier_zip.errors) ||
                              (supplierForm.controls['supplier_zip'].touched &&
                                !supplierForm.controls['supplier_zip'].valid)
                            "
                          >
                            <small class="text-danger">
                              <span
                                *ngIf="
                                  supplierForm.controls[
                                    'supplier_zip'
                                  ].hasError('required')
                                "
                              >
                                Zip Code Is Required
                              </span>
                              <span
                                *ngIf="
                                  supplierForm.controls[
                                    'supplier_zip'
                                  ].hasError('minlength')
                                "
                              >
                                Min Length is 5
                              </span>
                              <span
                                *ngIf="
                                  supplierForm.controls[
                                    'supplier_zip'
                                  ].hasError('pattern') &&
                                  !supplierForm.controls[
                                    'supplier_zip'
                                  ].hasError('minlength')
                                "
                              >
                                Please Enter Valid Only Number
                              </span>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group f2">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Country Name"
                        formControlName="supplier_country_id"
                        [ngClass]="{
                          'is-invalid':
                            submitteds3 && fs.supplier_country_id.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="500"
                      />
                      <!-- <select id="inputState" (change)="fetchState($any($event.target).value)"
                        class="form-control form-select" formControlName="supplier_country_id" name="country" required
                        [ngClass]="{ 'is-invalid': submitteds3 && fs.supplier_country_id.errors }">
                        <option selected [value]="233">United States</option>
                        <option *ngFor="let country of countries" [value]="country.id">
                          {{ country.name }}
                        </option>
                      </select> -->
                      <div
                        *ngIf="
                          (submitteds3 && fs.supplier_country_id.errors) ||
                          (supplierForm.controls['supplier_country_id']
                            .touched &&
                            !supplierForm.controls['supplier_country_id'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls[
                                'supplier_country_id'
                              ].hasError('required')
                            "
                          >
                            Country Is Required
                          </span>
                        </small>
                      </div>
                    </div>
                    <!-- <div class="form-group f2">
                      <ngx-intl-tel-input
                        [cssClass]="'custom'"
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[
                          SearchCountryField.Iso2,
                          SearchCountryField.Name
                        ]"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.UnitedStates"
                        [maxLength]="15"
                        [phoneValidation]="true"
                        [separateDialCode]="separateDialCode"
                        name="phone"
                        formControlName="supplier_phone"
                        (countryChange)="countryChange($event)"
                        appPhoneMask
                        [countryCode]="selectedCountryCode"
                      >
                      </ngx-intl-tel-input>

                      <div
                        *ngIf="
                          (submitteds3 && fs.supplier_phone.errors) ||
                          (supplierForm.controls['supplier_phone'].touched &&
                            !supplierForm.controls['supplier_phone'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls['supplier_phone'].hasError(
                                'required'
                              )
                            "
                          >
                            Phone Number Is Required
                          </span>
                          <span *ngIf="supplierForm.controls['supplier_phone'].hasError('pattern')">
                            Valid Only Number
                          </span>
                          <span
                            *ngIf="
                              !supplierForm.controls['supplier_phone']
                                .invalid == false &&
                              !supplierForm.controls['supplier_phone'].hasError(
                                'required'
                              )
                            "
                          >
                            Please Enter Valid Number
                          </span>
                        </small>
                      </div>
                    </div> -->

                    <div class="form-group f2">
                      <div class="stepper_button">
                        <button progressStepPrev class="form-control button3">
                          Previous
                        </button>
                        <button
                          [disabled]="
                            !(
                              this.supplierForm.controls.supplier_country_id
                                .valid &&
                              this.supplierForm.controls.supplier_state_id
                                .valid &&
                              this.supplierForm.controls.supplier_city.valid &&
                              this.supplierForm.controls.supplier_phone.valid &&
                              this.supplierForm.controls.supplier_zip.valid &&
                              this.supplierForm.controls.supplier_address.valid
                            )
                          "
                          class="form-control button2"
                          (click)="goNext(progress, 'supplier_info')"
                        >
                          Continue
                        </button>
                      </div>
                    </div>

                    <!-- <div class="row">
                      <button progressStepPrev mat-raised-button class=" btn btn-primary   button3">Previous</button>
                      <button [disabled]="!(
                          this.supplierForm.controls.supplier_country_id.valid &&
                          this.supplierForm.controls.supplier_state_id.valid &&
                          this.supplierForm.controls.supplier_city.valid &&
                          this.supplierForm.controls.supplier_phone.valid &&
                          this.supplierForm.controls.supplier_zip.valid &&
                          this.supplierForm.controls.supplier_address.valid)" mat-raised-button
                        class=" btn btn-primary button2" (click)="goNext(progress,'supplier_info')">
                        Continue
                      </button>
                    </div> -->
                  </div>
                </app-progress-step>

                <app-progress-step>
                  <div class="Details1">
                    <div>
                      <label class="labled"
                        >Add and Verify Bank information via
                      </label>
                      <br />

                      <div class="d-flex justify-content-around mt-2">
                        <div class="form-check labled ml-5">
                          <input
                            (change)="
                              handle_stripe($event, $any($event.target).value)
                            "
                            class="form-check-input"
                            type="radio"
                            name="bank_type"
                            id="exampleRadios1"
                            value="STRIPE"
                            checked
                            formControlName="bank_type"
                          />
                          <label class="form-check-label" for="exampleRadios1">
                            Stripe
                          </label>
                        </div>
                        <div class="form-check labled">
                          <input
                            (change)="
                              handle_plaid($event, $any($event.target).value)
                            "
                            class="form-check-input"
                            type="radio"
                            name="bank_type"
                            id="exampleRadios2"
                            value="PLAID"
                            formControlName="bank_type"
                          />
                          <label class="form-check-label" for="exampleRadios2">
                            Plaid
                          </label>
                        </div>
                      </div>
                    </div>

                    <!-- <ng-container
                      *ngIf="supplierForm.get('bank_type')?.value === 'STRIPE'"
                    > -->
                    <label class="labled">Bank Details</label>
                    <div class="form-group f2">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Account Holder Name"
                        formControlName="account_name"
                        [ngClass]="{
                          'is-invalid': submitteds2 && fs.account_name.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="100"
                      />
                      <div
                        *ngIf="
                          (submitteds2 && fs.account_name.errors) ||
                          (supplierForm.controls['account_name'].touched &&
                            !supplierForm.controls['account_name'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls['account_name'].hasError(
                                'required'
                              )
                            "
                          >
                            Account Holder Name is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['account_name'].hasError(
                                'minlength'
                              )
                            "
                          >
                            Min Length is 2
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['account_name'].hasError(
                                'maxlength'
                              )
                            "
                          >
                            Max Length is 100
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['account_name'].hasError(
                                'pattern'
                              )
                            "
                          >
                            Valid Only Character
                          </span>
                        </small>
                      </div>
                    </div>
                    <div class="form-group f2">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Bank Name"
                        formControlName="bank_name"
                        [ngClass]="{
                          'is-invalid': submitteds2 && fs.bank_name.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="100"
                      />
                      <div
                        *ngIf="
                          (submitteds2 && fs.bank_name.errors) ||
                          (supplierForm.controls['bank_name'].touched &&
                            !supplierForm.controls['bank_name'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls['bank_name'].hasError(
                                'required'
                              )
                            "
                          >
                            Bank Name is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['bank_name'].hasError(
                                'minlength'
                              )
                            "
                          >
                            Min Length is 2
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['bank_name'].hasError(
                                'maxlength'
                              )
                            "
                          >
                            Max Length is 100
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['bank_name'].hasError(
                                'pattern'
                              )
                            "
                          >
                            Valid Only Character
                          </span>
                        </small>
                      </div>
                    </div>

                    <div class="form-group f2">
                      <input
                        appNumbersOnly
                        type="text"
                        class="form-control"
                        placeholder="Account Number"
                        formControlName="account_number"
                        [ngClass]="{
                          'is-invalid': submitteds2 && fs.account_number.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="12"
                      />
                      <div
                        *ngIf="
                          (submitteds2 && fs.account_number.errors) ||
                          (supplierForm.controls['account_number'].touched &&
                            !supplierForm.controls['account_number'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls['account_number'].hasError(
                                'required'
                              )
                            "
                          >
                            Account Number is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['account_number'].hasError(
                                'minlength'
                              )
                            "
                          >
                            Min Length is 8
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['account_number'].hasError(
                                'maxlength'
                              )
                            "
                          >
                            Max Length is 12
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['account_number'].hasError(
                                'pattern'
                              ) &&
                              !supplierForm.controls['account_number'].hasError(
                                'minlength'
                              )
                            "
                          >
                            Please Enter Numbers Only
                          </span>
                        </small>
                      </div>
                    </div>

                    <div class="form-group f2">
                      <input
                        appNumbersOnly
                        type="text"
                        class="form-control"
                        placeholder="Confirm Account Number"
                        formControlName="con_account_number"
                        [ngClass]="{
                          'is-invalid':
                            submitteds2 && fs.con_account_number.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="12"
                      />
                      <div
                        *ngIf="
                          (submitteds2 && fs.con_account_number.errors) ||
                          (supplierForm.controls['con_account_number']
                            .touched &&
                            !supplierForm.controls['con_account_number'].valid)
                        "
                      >
                        <small class="text-danger">
                          <div
                            *ngIf="
                              (submitteds2 && fs.con_account_number.errors) ||
                              (supplierForm.controls['con_account_number']
                                .touched &&
                                !supplierForm.controls['con_account_number']
                                  .valid)
                            "
                          >
                            <span
                              *ngIf="
                                supplierForm.controls[
                                  'con_account_number'
                                ].hasError('required')
                              "
                            >
                              Confirm Account is Required
                            </span>
                            <span
                              *ngIf="
                                supplierForm.hasError(
                                  'mustMatch',
                                  'con_account_number'
                                )
                              "
                            >
                              Confirm Account Number Not Match
                            </span>
                          </div>
                        </small>
                      </div>
                    </div>

                    <div class="form-group f2">
                      <input
                        appNumbersOnly
                        type="text"
                        class="form-control"
                        placeholder="Routing Number"
                        formControlName="routing_no"
                        [ngClass]="{
                          'is-invalid': submitteds2 && fs.routing_no.errors
                        }"
                        (keydown)="spaceRemoveFirst($event)"
                        maxlength="11"
                      />
                      <div
                        *ngIf="
                          (submitteds2 && fs.routing_no.errors) ||
                          (supplierForm.controls['routing_no'].touched &&
                            !supplierForm.controls['routing_no'].valid)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="
                              supplierForm.controls['routing_no'].hasError(
                                'required'
                              )
                            "
                          >
                            Routing Number is Required
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['routing_no'].hasError(
                                'minlength'
                              )
                            "
                          >
                            Min Length is 9
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['routing_no'].hasError(
                                'maxlength'
                              )
                            "
                          >
                            Max Length is 11
                          </span>
                          <span
                            *ngIf="
                              supplierForm.controls['routing_no'].hasError(
                                'pattern'
                              ) &&
                              !supplierForm.controls['routing_no'].hasError(
                                'minlength'
                              )
                            "
                          >
                            Please Enter Numbers Only
                          </span>
                        </small>
                      </div>
                    </div>
                    <!-- </ng-container> -->

                    <div class="form-group f2" *ngIf="isShowPreButton">
                      <div class="stepper_button">
                        <button progressStepPrev class="form-control button3">
                          Previous
                        </button>
                        <button
                          *ngIf="
                            supplierForm.get('bank_type')?.value === 'STRIPE'
                          "
                          [disabled]="
                            !(
                              this.supplierForm.controls.account_name.valid &&
                              this.supplierForm.controls.account_number.valid &&
                              this.supplierForm.controls.routing_no.valid &&
                              this.supplierForm.controls.bank_name.valid &&
                              this.supplierForm.controls.con_account_number
                                .valid
                            )
                          "
                          class="form-control button2"
                          (click)="goNext(progress, 'bank_info')"
                        >
                          <ng-container *ngIf="isSignup; else isSignupDone">
                            <i
                              class="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          </ng-container>
                          <ng-template #isSignupDone> Submit </ng-template>
                        </button>
                        <button
                          *ngIf="
                            supplierForm.get('bank_type')?.value === 'PLAID'
                          "
                          class="form-control button2"
                          (click)="goNext(progress, 'bank_info')"
                        >
                          <ng-container *ngIf="isSignup; else isSignupDone">
                            <i
                              class="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          </ng-container>
                          <ng-template #isSignupDone> Submit </ng-template>
                        </button>
                      </div>
                    </div>
                    <!-- <div class="row" *ngIf="isShowPreButton">
                      <div progressStepPrev class=" btn btn-primary form-control button3">
                        Previous</div>

                      <button [disabled]="!(this.supplierForm.controls.account_name.valid &&
                        this.supplierForm.controls.account_number.valid &&
                        this.supplierForm.controls.routing_no.valid &&
                        this.supplierForm.controls.bank_name.valid &&
                        this.supplierForm.controls.ein_number.valid &&
                        this.supplierForm.controls.con_account_number.valid)" mat-raised-button
                        class=" btn btn-primary button2" (click)="goNext(progress,'bank_info')">

                        <ng-container *ngIf="isSignup ;else isSignupDone">
                          <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </ng-container>
                        <ng-template #isSignupDone>
                          Submit
                        </ng-template>
                      </button>
                    </div> -->
                  </div>
                </app-progress-step>
              </app-progress>
            </form>
          </div>
        </div>
        <div class="col-lg col-12">
          <h3 class="text-center grow mt-5">
            Grow your Business faster by selling on <br />
            HATRIK Marketplace
          </h3>
          <div class="right-img-wrapper">
            <div class="row">
              <div class="col-md div1-padding">
                <img src="assets/images/icons/image111.svg" alt="" />
                <div class="content-right">
                  <strong class="numeric-data">{{
                    vendorAddress?.total_supplier || 0
                  }}</strong>
                  <br />
                  <small class="text-data"
                    >Suppliers selling On <br />
                    HATRIK Marketplace</small
                  >
                </div>
              </div>
              <div class="col-md div1-padding">
                <img src="assets/images/icons/image114.svg" alt="" />
                <div class="content-right">
                  <strong class="numeric-data">24/7</strong>
                  <br />
                  <small class="text-data"
                    >Customer Support <br />
                    Available</small
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md div1-padding">
                <img
                  class="categories"
                  src="assets/images/icons/image112.svg"
                  alt=""
                />
                <div class="content-right">
                  <strong class="numeric-data">{{
                    vendorAddress?.total_category || 0
                  }}</strong>
                  <br />
                  <small class="text-data">Categories to sell</small>
                </div>
              </div>
              <div class="col-md div1-padding">
                <img src="assets/images/icons/image113.svg" alt="" />
                <div class="content-right">
                  <strong class="numeric-data">100%</strong>
                  <br />
                  <small class="text-data"
                    >Secure Payment <br />
                    Deposits</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="stepThreeForm">
    <div class="login-div col-lg-6">
      <div class="login-card">
        <p class="login">Welcome to Hatrik</p>
        <p class="please-your">Create your account to start selling</p>
        <div>
          <p class="a-o-i head" style="margin-top: 70px">
            Enter Verification Code
          </p>
          <div
            class="d-flex justify-content-center"
            style="margin-bottom: 70px"
          >
            <ngx-otp-input
              [config]="otpInputConfig"
              (otpChange)="handeOtpChange($event)"
              (fill)="handleFillEvent($event)"
            >
            </ngx-otp-input>
          </div>
        </div>

        <div class="form-footer d-flex">
          <!-- <button (click)="previous()" class="btn btn-primary form-control button3">
          Previous
        </button> -->
          <button
            class="btn btn-primary form-control ml-auto button3"
            (click)="Submit()"
          >
            SIGN UP
          </button>
        </div>
      </div>
    </div>
    <div class="login-div-left col-lg-6">
      <h4>
        Start Selling your Products on Hatrik <br />
        Right from your doorstep!
      </h4>
      <div class="d-flex justify-content-center bg-1">
        <img src="assets/images/Img.png" class="images" />
      </div>
      <div>
        <h6 class="text">All you need to sell on Hatrik is</h6>
      </div>
      <div class="container">
        <!-- <div class="d-flex justify-content-center"> -->
        <div class="row footer-icon">
          <div class="col-sm">
            <img
              src="assets/images/Gst.png"
              style="width: 60px; height: 60px"
            />
          </div>
          <div class="col-sm">
            <img
              src="assets/images/bank.png"
              style="width: 60px; height: 60px"
            />
          </div>
          <div class="col-sm">
            <img src="assets/images/product.png" class="products" />
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
