import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authservice: AuthService, private _router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authservice.loggedIn()) {
      let profileStep = Number(localStorage.getItem('pofile_step'))
      if (profileStep ===0) {
        this._router.navigate(['/create-supplier-account'])
        return false;
      }
      return true
    } else {
      this._router.navigate(['/'])
      return false
    }
  }

}
