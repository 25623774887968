import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-regenrate',
  templateUrl: './regenrate.component.html',
  styleUrls: ['./regenrate.component.scss']
})
export class RegenrateComponent implements OnInit {

  constructor(private ApiService: ApiService, private _ToastrService: ToastrService,
    private _AuthService: AuthService) { }

  ngOnInit(): void {
    this._AuthService.logOutVendor().subscribe((data: any) => {
      let status = data.status
      if (status == 200) {
        localStorage.clear();
      }
    },
      (error: any) => {
        this._ToastrService.error(error.error.message)
      })
  }

  // tryAgain() {
  //   this.ApiService.getRegenerateStripeUrl().subscribe((res: any) => {
  //     if (res.isSucess) {
  //       let stripeURL = res?.url;
  //       window.location.href = stripeURL;
  //     } else {
  //       this._ToastrService.error(res.message || "someting went wrong")
  //     }
  //   },
  //     (error: any) => {
  //       this._ToastrService.error(error.error.message)
  //     })
  // }
}

