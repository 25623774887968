<div class="container-fluid">
  <div class="row height-d">
    <div class="col p-0 position-relative">
      <img class="corner-left-background" src="assets/images/top.png" />
      <img class="logo-center" src="assets/images/Hatrik-logo.png" />
      <img class="corner-right-background" src="assets/images/bottom.png" />
    </div>

    <div
      class="col d-flex align-items-center justify-content-center p-0"
      style="background-color: #f6f7fb"
    >
      <div class="right-div-login">
        <div class="d-flex justify-content-end">
          <img
            style="width: 125px"
            src="assets/images/Hatrik-corner.svg"
            alt=""
          />
        </div>
        <div class="login-card mt-3">
          <h2 class="login">Login to your supplier panel</h2>
          <p class="please-your">Please fill your details below</p>
          <form [formGroup]="loginForm" (ngSubmit)="postData()">
            <div class="form-group">
              <label for="exampleInputEmail1"
                >Email <span class="asterisk">*</span></label
              >
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter your email"
                formControlName="login"
                [ngClass]="{ 'is-invalid': submittedf && lf.login.errors }"
              />

              <span
                class="text-danger"
                *ngIf="
                  (submittedf && lf.login.errors) ||
                  (loginForm.get('login')?.touched &&
                    loginForm.get('login')?.hasError('required'))
                "
                ><small>Enter your valid email</small></span
              >
            </div>
            <div class="form-group mt-3 icon-div">
              <label for="exampleInputPassword1"
                >Password <span class="asterisk">*</span></label
              >
              <input
                matInput
                type="password"
                formControlName="password"
                class="form-control"
                placeholder="Enter your password"
                [type]="hide ? 'password' : 'text'"
                [ngClass]="{ 'is-invalid': submittedf && lf.password.errors }"
              />
              <mat-icon matSuffix (click)="hide = !hide">{{
                hide ? "visibility_off" : "visibility"
              }}</mat-icon>
            </div>
            <span
              class="text-danger"
              *ngIf="
                (submittedf && lf.password.errors) ||
                (loginForm.get('password')?.touched &&
                  loginForm.get('password')?.hasError('required'))
              "
              ><small>Enter your valid password</small></span
            >
            <button
              type="submit"
              class="btn btn-primary btn-lg btn-block mt-3 butto input-btn-login"
            >
              <ng-container *ngIf="isLogged; else isLoggedDone">
                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              </ng-container>
              <ng-template #isLoggedDone> Log in </ng-template>
            </button>
            <p class="mt-2 text-right mb-0" [routerLink]="['/forgot-password']">
              <a>Forgot your password?</a>
            </p>
          </form>
        </div>
        <h6>New to HATRIK ?</h6>
        <button
          type="button"
          class="btn create-supplier"
          [routerLink]="['/create-supplier-account']"
        >
          Create your Supplier Account
        </button>
      </div>
    </div>
  </div>
</div>
