import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  constructor(private _AuthService: AuthService, private _ToastrService: ToastrService) { }

  ngOnInit(): void {
    this._AuthService.logOutVendor().subscribe((data: any) => {
      let status = data.status
      if (status == 200) {
        localStorage.clear();
      }
    },
      (error: any) => {
        // this._ToastrService.error(error.error.message)
      })
  }

}
