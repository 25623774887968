import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { ProgressComponent } from './components/progress/progress.component';
import { ProgressStepDirective } from './components/progress/progress-step.directive';
import { ProgressStepComponent } from './components/progress/progress-step/progress-step.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { NgxOtpInputModule } from "ngx-otp-input";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { MaterialExampleModule } from '../material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatStepperModule } from '@angular/material/stepper';
import { AuthService } from './shared/services/auth.service';
import { AuthGuard } from './shared/guards/auth.guard';
import { LogInGuard } from './shared/guards/log-in.guard';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { PrivacyAndPolicyComponent } from './components/privacy-and-policy/privacy-and-policy.component';
import { TermandconditionComponent } from './components/termandcondition/termandcondition.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SuccessComponent } from './components/success/success.component';
import { RegenrateComponent } from './components/regenrate/regenrate.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PhoneMaskDirective } from './shared/helpers/phone-mask.directive';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NumbersOnlyDirective } from './shared/helpers/numbers-only.directive';
import { DirectivesModuleModule } from './shared/helpers/directives-module/directives-module.module';





// import { ApiInterceptorService } from './shared/interceptor/api-interceptor.service';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ProgressComponent,
    ProgressStepDirective,
    ProgressStepComponent,
    ForgotPasswordComponent,
    LandingPageComponent,
    PrivacyAndPolicyComponent,
    TermandconditionComponent,
    SuccessComponent,
    RegenrateComponent,
    PhoneMaskDirective,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatButtonModule,
    ButtonModule,
    SidebarModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      progressBar: true,
      enableHtml: true,

    }),
    NgHttpLoaderModule.forRoot(),
    MatInputModule,
    NgxOtpInputModule,
    HttpClientModule,
    MaterialExampleModule,
    MatStepperModule,
    NgbModule,
    NgxIntlTelInputModule,
    GooglePlaceModule,
    DirectivesModuleModule
  ],
  providers: [AuthGuard, AuthService, LogInGuard,
  ],
  // {    provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true
  // }


  bootstrap: [AppComponent]
})
export class AppModule { }
