import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  submittedf = false;
  hide = true;
  isLogged: boolean = false;

  constructor(
    private router: Router,
    private _FormBuilder: FormBuilder,
    private _AuthService: AuthService,
    private _ToastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.loginForm = this._FormBuilder.group({
      login: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  get lf() {
    return this.loginForm.controls;
  }

  postData() {
    this.isLogged = true;
    this.submittedf = true;
    if (this.loginForm.valid) {
      let body = {
        login: this.loginForm.controls.login.value,
        db: environment.db_name,
        password: this.loginForm.controls.password.value,
      };
      this._AuthService.login(body).subscribe(
        (res: any) => {
          this.isLogged = false;
          localStorage.setItem('pofile_step', res.profile_step);
          localStorage.setItem('userToken', res.access_token);
          localStorage.setItem('partnerId', res.partner_id);
          localStorage.setItem('name', res.name);
          localStorage.setItem('vendorImages', res.image);
          localStorage.setItem('userTrusted', res.is_user_trusted);
          if (
            res.profile_step == 0 ||
            res.profile_step == 1 ||
            res.profile_step == 2 ||
            res.profile_step == 3
          ) {
            this.router.navigate(['/create-supplier-account']);
          } else {
            this._ToastrService.success('You are successfully logged in');
            this.router.navigate(['/pages']).then(() => {
              window.location.reload();
            });
          }
        },
        (error: any) => {
          this._ToastrService.error(error.error.message);
          this.isLogged = false;
        }
      );
    }
  }
}
