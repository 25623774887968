import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  addNewAddress(body: { type: any; name: any; city: any; state_id: number; country_id: number; street: any; street2: any; zip: any; mobile: any; }) {
    throw new Error('Method not implemented.');
  }
  token: any

  headers = new HttpHeaders({
    'Content-Type': 'text/plain',
    'Access-Control-Allow-Origin': '*',
  });

  httpOpts = { headers: this.headers };

  constructor(private http: HttpClient) {
    const token = localStorage.getItem('userToken');

  }


  SignUp(data: any) {
    return this.http.post(
      `${environment.baseUrl}/v1/v/vendor_signup`,
      data,
      this.httpOpts
    );
  }
  getVendorInfo() {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.post(
      `${environment.baseUrl}/v1/v/get_vendor_address`,
      {},
      { headers: headers }
    );
  }
  otpVerify(data: any) {
    return this.http.post(`${environment.baseUrl}/v1/c/customer/sendotp`, data, this.httpOpts)
  }
  saveBankDetail(data: any) {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.post(`${environment.baseUrl}/v1/v/vendor_bank_information`, data, { headers: headers })
  }
  saveBusinessDetail(data: any) {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.post(`${environment.baseUrl}/v1/v/vendor_business_information`, data, { headers: headers })
  }
  saveSupplierDetail(data: any) {

    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.post(`${environment.baseUrl}/v1/v/vendor_supplier_update`, data, { headers: headers })
  }
  getCountry() {
    return this.http.get(
      `${environment.baseUrl}/v1/c/get_country_state`,
    );
  }
  getState(id: any) {
    return this.http.get(
      `${environment.baseUrl}/v1/c/get_country_state?country_id=${id}`,

    );
  }


  login(data: any) {
    return this.http.post(
      `${environment.baseUrl}/vendor/auth/token`, data, this.httpOpts
    )
  }


  logOutVendor() {
    const token = localStorage.getItem('userToken');
    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'text/plain');
    // headers = headers.append('Access-Control-Allow-Origin', '*');
    // headers = headers.append('access-token', token);
    return this.http.post(
      `${environment.baseUrl}/logout/auth/token`, { access_token: token }, this.httpOpts
    )

  }

  loggedIn() {
    return localStorage.getItem('userToken');
  }

  loginuser() {
    return !localStorage.getItem('userToken');
  }

  forgotPasswordOtp(data: any) {
    return this.http.post(`${environment.baseUrl}/v1/c/customer/forgot_password_send_otp`, data, this.httpOpts)
  }

  forgotPassword(data: any) {
    return this.http.post(`${environment.baseUrl}/v1/v/vendor/forgot_password`, data, this.httpOpts)
  }

  postCatalogue(data: any) {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.post(
      `${environment.baseUrl}/v1/v/product_product`, data, { headers: headers }
    )
  }
  topSellingList(pageNo: number) {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.post(
      `${environment.baseUrl}/v1/v/product.template.view?orderBy=featured&page=${pageNo}`, '', { headers: headers }
    )
  }


  viewProfile() {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.post(`${environment.baseUrl}/v1/v/get_vendor_address`, '', { headers: headers })
  }


  editProfile(data: any) {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.post(
      `${environment.baseUrl}/v1/v/vendor_profile_update`, data,
      { headers: headers }
    );
  }


  pickingAddressCreate(data: any) {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.post(
      `${environment.baseUrl}/v1/v/picking_address_create`, data, { headers: headers }
    )
  }

  pickingAddressUpdate(id: any, data: any) {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.post(
      `${environment.baseUrl}/v1/v/picking_address_update/${id}`, data, { headers: headers }
    )
  }


  pickingAddressGet() {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.get(
      `${environment.baseUrl}/v1/v/get_picking_address`, { headers: headers }
    )
  }


  pickingAddressGetById(id: any) {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.get(
      `${environment.baseUrl}/v1/v/get_picking_address/${id}`, { headers: headers }
    )
  }

  pickingAddressDeleteById(id: any) {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.post(
      `${environment.baseUrl}/v1/v/picking_delete/${id}`, '', { headers: headers }
    )
  }
  resPartnerViewDelete() {
    this.token = localStorage.getItem('userToken');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('access-token', this.token);
    return this.http.post(
      `${environment.baseUrl}/v1/c/res.partner.view.delete`, '', { headers: headers }
    )
  }
  checkMobileNumber(data: any) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    // headers = headers.append("access-token", localStorage.getItem("userToken") || '')
    return this.http.post(
      `${environment.baseUrl}/v1/apk/check_mobile_number`, data, { headers: headers }
    )
  }
}





