import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  answershow1: boolean = false;
  answershow2: boolean = false;
  answershow3: boolean = false;
  answershow4: boolean = false;

  fbUrl: any
  twitterUrl: any
  instaUrl: any

  constructor(
    private _ApiService: ApiService,
    private _ToastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getSocialMediaLink()
  }

  on1() {
    this.answershow1 = true;
    this.answershow2 = false;
    this.answershow3 = false;
    this.answershow4 = false;

  }

  on2() {
    this.answershow1 = false;
    this.answershow2 = true;
    this.answershow3 = false;
    this.answershow4 = false;

  }

  on3() {
    this.answershow1 = false;
    this.answershow2 = false;
    this.answershow3 = true;
    this.answershow4 = false;

  }

  on4() {
    this.answershow1 = false;
    this.answershow2 = false;
    this.answershow3 = false;
    this.answershow4 = true;
  }


  getSocialMediaLink() {
    this._ApiService.getSocialMediaLink().subscribe((data: any) => {
      if (data.status == 200) {
        this.fbUrl = data.records[0].base_url
        this.twitterUrl = data.records[1].base_url
        this.instaUrl = data.records[2].base_url
      }
    },
      (error: any) => {
        let status_code = error.error.status_code;
        let message = error.error.message
        if (status_code == 400) {
          this._ToastrService.error(message);
        }
      }
    )

  }

}
