import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoginComponent } from './components/login/login.component';
import { PrivacyAndPolicyComponent } from './components/privacy-and-policy/privacy-and-policy.component';
import { RegenrateComponent } from './components/regenrate/regenrate.component';
import { SignupComponent } from './components/signup/signup.component';
import { SuccessComponent } from './components/success/success.component';
import { TermandconditionComponent } from './components/termandcondition/termandcondition.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LogInGuard } from './shared/guards/log-in.guard';

const routes: Routes = [

  // {
  //   path:'',
  //   redirectTo:'landing-page',
  //   pathMatch:'full'

  // },

  {
    path: '',
    canActivate: [LogInGuard],
    component: LandingPageComponent
  },


  {
    path: 'login',
    canActivate: [LogInGuard],
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    canActivate: [LogInGuard],
    component: ForgotPasswordComponent
  },
  {
    path: 'create-supplier-account',
    component: SignupComponent
  },
  {
    path: 'privacy and policy',
    component: PrivacyAndPolicyComponent
  },
  {
    path: 'terms and conditions',
    component: TermandconditionComponent
  },
  {
    path: 'success',
    component: SuccessComponent,
  },
  {
    path: 'regenrate',
    component: RegenrateComponent,
  },
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '**', redirectTo: '', pathMatch: 'full'
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
