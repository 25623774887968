import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NgxOtpInputConfig } from 'ngx-otp-input/lib/component/ngx-otp-input.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotForm!: FormGroup;
  submittedf = false;
  submitted = false;
  hide = true;
  otp: any;

  step = 1;


  constructor(
    private router: Router,
    private _FormBuilder: FormBuilder,
    private _AuthService: AuthService,
    private _ToastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.forgotForm = this._FormBuilder.group({
      login: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{7,}')]],
    });
  }

  get lf() {
    return this.forgotForm.controls;
  }

  resedOtp() {
    let body = {
      email: this.forgotForm.controls.login.value,
    };
    this._AuthService.forgotPasswordOtp(body).subscribe((res: any) => {
      let status = res.status_code;
      if (status == 200) {
        this._ToastrService.success('OTP Resend Your E-mail Address');
      }
    },
      (error: any) => {
        this._ToastrService.error(error.error.message);
      }
    );


  }

  previous() {
    this.step = this.step - 1;
  }

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };

  handeOtpChange(value: string[]): void { }

  handleFillEvent(value: string): void {
    this.otp = value;
  }


  postData() {
    if (this.step == 1) {
      this.submittedf = true;
      if (this.forgotForm.controls.login.valid) {
        let body = {
          email: this.forgotForm.controls.login.value,
        };
        this._AuthService.forgotPasswordOtp(body).subscribe(
          (res: any) => {
            let status = res.status_code;
            if (status == 200) {
              this._ToastrService.success('OTP Sent Your E-mail Address');
              this.step = this.step + 1;
            }
          },
          (error: any) => {
            this._ToastrService.error(error.error.message);
          }
        );
      }
    }

    if (this.step == 2) {
      this.submitted = true;
      if (this.forgotForm.valid) {
        let body = {
          email: this.forgotForm.controls.login.value,
          otp: this.otp,
          password: this.forgotForm.controls.password.value,
        };
        this._AuthService.forgotPassword(body).subscribe(
          (res: any) => {
            let status = res.status_code;
            if (status == 200) {
              this._ToastrService.success(res.message);
              this.router.navigate(['/login']);
            } else {
              this._ToastrService.error(res.message);
            }
          },
          (error: any) => {
            this._ToastrService.error(error.error.message);
          }
        );
      }
    }
  }

}
