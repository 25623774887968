<ng-container style="background:#ffffff;">
    <div class="header  d-flex justify-content-between w-100">
        <div class="mt-3">
            <a href="#"><img src="assets/images/Hatrik-logo.png" width="97" height="45" alt="" /></a>

        </div>
        <div class="mt-3">
            <div>
                <button type="button" class="btn btn-outline-light btn-outline-light1" routerLink='/login'>
                    Login
                </button>
            </div>
        </div>
    </div>
    <div class=" img-wrapper">
        <img src="../../../assets/images/submission.png" class="img-fluid">
        <p class="title text-center mt-3">
            Thanks for your submission
        </p>
        <h3 class="subtitle text-center">
            You will be notified once your request gets approved!
        </h3>

    </div>
</ng-container>