<div class="container-fluid">
  <div class="row">

    <div class="col-md height-d">
      <!-- <div class="corner-left"> -->
      <img
        class="corner-left-background"
        src="../../../assets/images/top.png"
        alt=""
      />
      <!-- </div> -->
      <div id="content">
        <img
          src="../../../assets/images/Hatrik-logo.png"
          width="363"
          height="169"
        />
      </div>
      <div class="corner-right">
        <img
          class="corner-right-background"
          src="../../../assets/images/bottom.png"
          alt=""
        />
      </div>
    </div>

    <div class="col-md height-d right-div-login">
      <div class="login-card px-5 py-3">
        <p class="login" style="text-align: center">Forgot Password</p>

        <form [formGroup]="forgotForm" (ngSubmit)="postData()">
          <div *ngIf="step == 1">
            <p class="please-your">
              Enter your Email for verification process . We will send you a
              6-digit's verification code in your Email
            </p>
            <div class="form-group">
              <label for="exampleInputEmail1" class="mt-4">Email</label>
              <input
                type="email"
                class="form-control mt-2"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Your Email"
                formControlName="login"
                [ngClass]="{ 'is-invalid': submittedf && lf.login.errors }"
              />

              <span
                class="text-danger"
                *ngIf="
                  (submittedf && lf.login.errors) ||
                  (forgotForm.get('login')?.touched &&
                    forgotForm.get('login')?.hasError('required'))
                "
                ><small>Enter Your Valid Email</small></span
              >
            </div>
          </div>

          <div *ngIf="step == 2">
            <p class="please-your">
              Enter the 6-digits code that you recieved on your Email<b>
                ({{ this.forgotForm.controls.login.value }}).</b
              >
            </p>

            <p class="a-o-i head mt-4" style="text-align: center">
              Enter Verification Code
            </p>
            <div
              class="d-flex justify-content-between"
              style="margin-bottom: 30px"
            >
              <ngx-otp-input
                [config]="otpInputConfig"
                (otpChange)="handeOtpChange($event)"
                (fill)="handleFillEvent($event)"
                class="ngx-otp"
              >
              </ngx-otp-input>
            </div>

            <div class="form-group mt-2">
              <label for="exampleInputPassword1">Set New Password</label>
              <input
                matInput
                type="password"
                formControlName="password"
                class="form-control mt-2"
                placeholder="Enter Your Password"
                [type]="hide ? 'password' : 'text'"
                [ngClass]="{ 'is-invalid': submitted && lf.password.errors }"
              />
              <mat-icon matSuffix (click)="hide = !hide">{{
                hide ? "visibility_off" : "visibility"
              }}</mat-icon>

              <div
                *ngIf="
                  (submitted && lf.password.errors) ||
                  (forgotForm.controls['password'].touched &&
                    !forgotForm.controls['password'].valid)
                "
              >
                <small class="text-danger">
                  <span
                    *ngIf="forgotForm.controls['password'].hasError('required')"
                  >
                    Password is Required
                  </span>
                  <span
                    *ngIf="forgotForm.controls['password'].hasError('pattern')"
                  >
                    Password should be at least 8 characters and contain a
                    combination of lettters, numbers and special characters
                  </span>
                </small>
              </div>
            </div>

            <div class="d-flex justify-content-end Resend" (click)="resedOtp()">
              Resend
            </div>
          </div>

          <button
            *ngIf="step == 1"
            type="submit"
            class="btn btn-primary btn-lg btn-block mt-4 butto input-btn-login"
          >
          Recover Password
          </button>

          <div class="d-flex">
            <button
              *ngIf="step == 2"
              (click)="previous()"
              class="btn btn-primary btn-lg btn-block mt-4 butto input-btn-login1"
            >
              Previous
            </button>

            <button
              *ngIf="step == 2"
              type="submit"
              class="btn btn-primary btn-lg btn-block mt-4 butto input-btn-login1 ml-auto"
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      <h6><span>New To HATRIK ?</span></h6>
      <div class="d-flex justify-content-center button1">
        <button
          type="button"
          class="btn btn-outline-primary px-8"
          [routerLink]="['/login']"
        >
          Login
        </button>
      </div>
    </div>
  </div>
</div>
